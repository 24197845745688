import {api} from "../config/apiConfig";

const register = async (payload: any) => {
  const res = await api.post("/auth/register", payload);
  return res.data;
};

const login = async (payload: any) => {
  const res = await api.post("/auth/login", payload);
  return res.data;
};

export default { register, login };
