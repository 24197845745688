import React, { useRef, useState } from "react";
import { IoIosArrowDropleft } from "react-icons/io";
import COLORS from "../constants/Colors";
import { Link, useNavigate } from "react-router-dom";
import uploadIcon from "../assets/icons/upload_icon.svg";
import { GoPlus } from "react-icons/go";
import ActionButton from "../components/ActionButton";
import { MdDeleteOutline } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import useToast from "../hooks/useToast";
const type = process.env.REACT_APP_TYPE;
const IP = process.env.REACT_APP_IP;
const REACT_APP_PROD_SSL_SWITCH = process.env.REACT_APP_PROD_SSL_SWITCH;
const REACT_APP_PROD_SSL_PORT = process.env.REACT_APP_PROD_SSL_PORT;
const BACKEND_URL =
  type == "dev" ? "http://localhost:5000" : `${REACT_APP_PROD_SSL_SWITCH}://${IP}:${REACT_APP_PROD_SSL_PORT}`;
const Kyc = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<any[]>([]);

  const handleFileChange = (e: any) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      if (selectedFiles.length + files.length > 5) {
        alert("You can only upload up to 5 files.");
      } else {
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles].slice(0, 5));
      }
    }
  };

  async function getS3SignUrl(filename: string, filetype: string) {
    const headers = new Headers({ "Content-Type": "application/json" });
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ fileName: filename, fileType: filetype }),
    };
    const response = await fetch(`${BACKEND_URL}/v1/user/uploadImage`, options);
    const presignedUrl = await response.json();
    return presignedUrl;
  }

  async function pushProfilePhotoToS3(presignedUrl: string, file: File) {
    const myHeaders = new Headers({ "Content-Type": file.type });
    const response = await fetch(presignedUrl, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    });
    return response;
  }
  function getUrlUpToExtension(url: string) {
    const queryParamIndex = url.indexOf("?");
    const endIndex = queryParamIndex !== -1 ? queryParamIndex : url.length;
    return url.substring(0, endIndex);
  }

  async function handleSubmit() {
    // event.preventDefault();

    if (files[0]) {
      const data = await getS3SignUrl(files[0].name, files[0].type);
      console.log(data, "data");
      if (data.url) {
        const response = await pushProfilePhotoToS3(data.url, files[0]);
        if (response) {
          console.log("Upload successful");
          toast.success("Upload successful");
          navigate("/interest");
          // setupload(true);
          // setFileLink(getUrlUpToExtension(response.url));
        } else {
          console.error("Upload failed");
          toast.error("Upload failed");
          // setupload(false);
        }
      }
    }
  }

  return (
    <div className="flex flex-col min-h-screen my-8 mx-4">
      <div className="flex flex-row items-center justify-between gap-x-3">
        <IoIosArrowDropleft
          size={24}
          color={COLORS.purple}
          onClick={() => navigate("/onboarding")}
        />
        <Link to="/interest">
          <p className="font-inter text-lg text-purple">Skip</p>
        </Link>
      </div>
      <div className="my-5">
        <h4 className="font-inter text-xl text-center">
          Upload ID for verification
        </h4>
        <p className="text-greyText font-roboto font-medium text-center mt-2">
          We strongly give full freedom to our users, but to avoid any kind of
          mishap & nuisance we reccomend you to provide a ID proof for safety &
          security
        </p>
      </div>
      <div>
        <p className="text-greyText font-roboto font-medium mt-2">
          Upload College ID and eKYC
        </p>
        <div
          onClick={() => inputRef.current?.click()}
          className="w-[100%] h-[300px] my-4 flex flex-col justify-center items-center rounded-xl border-[1px] border-[#99A9D1] bg-lightBlue"
        >
          <img src={uploadIcon} alt="upload" />
          <div className="border-[1px] border-greyText border-dotted rounded-full p-2 mt-2">
            <GoPlus size={24} color={COLORS.greyText} />
          </div>
          <p className="text-greyText font-roboto font-light mt-2">
            Upload document
          </p>
        </div>
        <input
          type="file"
          className="hidden"
          ref={inputRef}
          onChange={handleFileChange}
          accept="image/*"
        />
        {/* <p className="text-greyText font-roboto font-medium mt-2">
          *Upload upto 5 documents in JPEG format
        </p> */}
      </div>
      <div>
        {files.map((file, index) => (
          <div className="flex justify-between items-center">
            <div key={index} className="flex gap-x-2 my-2">
              <img
                className="w-8 h-8 object-cover"
                src={URL.createObjectURL(file)}
                alt={`preview-${index}`}
                width="100"
              />
              <p>
                {file.name.length > 10
                  ? `${file.name.slice(0, 10)}...`
                  : file.name}
              </p>
            </div>
            <div
              className="p-3"
              onClick={() => setFiles(files.filter((_, i) => i !== index))}
            >
              <RiDeleteBin6Line size={18} color={COLORS.purple} />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-auto flex flex-col gap-y-6 w-full py-8">
        <ActionButton
          buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md py-2"
          buttonText="Continue"
          onClick={handleSubmit}
        />
        <ActionButton
          buttonClass="bg-gradient-to-br from-secondary to-lightSecondary rounded-md py-2"
          buttonText="Skip"
          onClick={() => {
            navigate("/interest");
          }}
        />
      </div>
    </div>
  );
};

export default Kyc;
