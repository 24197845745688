import React, { useState } from "react";
import { IoIosArrowDropleft } from "react-icons/io";
import COLORS from "../constants/Colors";
import { Link, useNavigate } from "react-router-dom";
import FormInput from "../components/FormInput";
import { MdMyLocation } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import ActionButton from "../components/ActionButton";

const Location = () => {
  const navigate = useNavigate();
  const [ordinates, setOrdinates] = useState<any>({
    latitude: "",
    longitude: "",
  });

  const getCurrentLocation = () => {
    console.log("getCurrentLocation");
    navigator.geolocation.getCurrentPosition(function (location) {
      console.log(location.coords.latitude);
      console.log(location.coords.longitude);
      console.log(location.coords.accuracy);
      setOrdinates(location.coords);
    });
  };

  return (
    <div className="flex flex-col min-h-screen my-8 mx-4">
      <div className="flex flex-row items-center justify-between gap-x-3">
        <IoIosArrowDropleft
          size={24}
          color={COLORS.purple}
          onClick={() => navigate("/interest")}
        />
        <Link to="/">
          <p className="font-inter text-lg text-purple">Skip</p>
        </Link>
      </div>
      <div className="my-5">
        <h4 className="font-inter text-xl text-center">Add your location</h4>
        <p className="text-greyText font-roboto font-medium text-center mt-2">
          Let the app locate you to provide best searched results around you
        </p>
        <div className="flex flex-col gap-y-5 my-8">
          <FormInput
            placeholder="Current Location"
            onChange={() => {}}
            value={
              ordinates?.latitude && ordinates?.longitude
                ? `lat=${ordinates?.latitude}` +
                  ", " +
                  `lat=${ordinates?.longitude}`
                : ""
            }
            type="text"
            className="border-[1px] border-greyText px-2 py-2 rounded-md bg-lightBlue"
            suffix={
              <MdMyLocation
                size={18}
                color={COLORS.greyText}
                onClick={() => getCurrentLocation()}
              />
            }
          />
          {/* <FormInput
            placeholder="State"
            onChange={() => {}}
            value={""}
            type="text"
            className="border-[1px] border-greyText px-2 py-2 rounded-md bg-lightBlue"
            suffix={<CiSearch size={18} color={COLORS.greyText} />}
          /> */}
        </div>
        <div className="mt-8">
          <ActionButton
            buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md"
            buttonText="Continue"
            onClick={() => navigate("/profile")}
          />
        </div>
      </div>
    </div>
  );
};

export default Location;
