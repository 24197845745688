import React, { useMemo } from "react";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { useLocation } from "react-router-dom";
import { IconButton, Avatar, TextField, Button } from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { api } from "../config/apiConfig";
import { toast } from "react-hot-toast";
import { useAuth } from "../context/AuthContext";

const Heading = ({ title }: { title: string }) => {
  const { account } = useAuth();
  const { pathname } = useLocation();
  const checkPath = useMemo(
    () =>
      (pathname.includes("/chat") && pathname != "/chat") ||
      (pathname.includes("/room") && pathname != "/room"),
    [pathname]
  );
  const checkRoom = useMemo(
    () => pathname.includes("/room") && pathname != "/room",
    [pathname]
  );
  // <BsShare className='cursor-pointer hover:text-black' onClick={()=>{navigator.clipboard.writeText(window.location.href);toast.success("URL Copied")}}/>

  const handleCopyLink = async (e: any) => {
    e.preventDefault();
    try {
      console.log("Pathname copied", pathname.split("/")[2]);
      const res = await api.post(
        `chat/generate-invite/${pathname.split("/")[2]}`
      );
      console.log("The result", res);
      navigator.clipboard.writeText(res.data.inviteLink);
      toast.success("URL Copied");
    } catch (e) {
      console.log(e);
      toast.error("Not the admin");
    }
  };
  return (
    <div className="fixed w-full flx-row justify-between px-[0.5rem] mb-[1rem] backdrop-blur-md z-10">
      <div className="flx-row gap-3 text-2xl">
        
        <svg
          width="36"
          height="35"
          viewBox="0 0 36 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            window.history.back();
          }}
        >
          <rect
            x="1"
            y="0.925171"
            width="33.5642"
            height="33.1283"
            rx="16.5642"
            stroke="#99A9D1"
          />
          <path
            d="M20.5642 11.9252L15 17.4893L20.5642 23.0535"
            stroke="#7A54F0"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span>{title}</span>
      </div>

      {checkPath ? (
        <div className="flx-row gap-1 text-md ">
          <IconButton>
            <LocalPhoneOutlinedIcon className="text-purple" />
          </IconButton>
          <IconButton>
            <VideocamOutlinedIcon className="text-purple" />
          </IconButton>
          {checkRoom && (
            <IconButton onClick={handleCopyLink}>
              <ShareIcon className="text-purple" />
            </IconButton>
          )}
        </div>
      ) : (
        <div className="flx-row gap-1 text-md">
          <IconButton>
            <NotificationsOutlinedIcon />
          </IconButton>

          <Avatar
            alt="Profile Pic"
            src={account?.profilePic}
            sx={{ width: 35, height: 35 }}
          />
        </div>
      )}
    </div>
  );
};

export default Heading;
