import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { api } from "../config/apiConfig";

const RedirectGroup = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const loadContents = async () => {
      try {
        if (pathname.includes("join")) {
          const id = pathname.split("/")[2];

          const res = await api.post(`chat/accept-invite/${id}`);
          console.log("Redirected to room", res);
          window.location.href = `/room/${res.data.chatId}`;
        }
      } catch (error) {
        console.error("Error getting rooms", error);
      }
    };

    loadContents();
  }, []);
  return <div>Loading.....</div>;
};

export default RedirectGroup;
