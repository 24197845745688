import { createSlice } from "@reduxjs/toolkit";

interface State {
    quizeList: any[],
    selectedQuize: Object | null | any,
    questionsList: any[],
    selectedQuestion: Object | null | any,
}

const initialState: State = {
    quizeList: [],
    selectedQuize: {},
    questionsList: [],
    selectedQuestion: {}
}

const QuizeSlice = createSlice({
    initialState: initialState,
    name: 'quize',
    reducers: {
        setQuizeList(state, action) {
            state.quizeList = action.payload;
        },
        setSelectedQuize(state, action) {
            state.selectedQuize = action.payload
        },
        setQuestionList(state, action) {
            state.questionsList = action.payload.map((q: any) => ({ ...q, answer: '' }))
        },
        setSelectedQuestion(state, action) {
            state.selectedQuestion = action.payload
        },
        updateQuestion(state, action) {
            const { id, data } = action.payload;
            state.questionsList = state.questionsList.map(q =>
                q.id === id ? { ...q, answer: data.answer } : q
            );
        },
    }
})

export default QuizeSlice.reducer
export const { setQuizeList, setSelectedQuize, setQuestionList, setSelectedQuestion, updateQuestion } = QuizeSlice.actions

export const quize_list = (state: { quize: State }) => state.quize.quizeList
export const selected_quize = (state: { quize: State }) => state.quize.selectedQuize
export const question_list = (state: { quize: State }) => state.quize.questionsList
export const selected_question = (state: { quize: State }) => state.quize.selectedQuestion

