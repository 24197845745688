import React from "react";
import { Link, useNavigate } from "react-router-dom";
import MatchPopUp from "../components/popup/MatchPopup";
const PrivacyPolicy = require("../assets/docs/Privacy&Policy.pdf");
const TermsAndConditions = require("../assets/docs/duocortex_t_and_c.pdf")

const SideBar = () => {
  const array = [
    {
      name: "Profile",
      url: "/profile",
    },
    {
      name: "Forum",
      url: "/forum",
    },
    {
      name: "Give Quiz",
      url: "/quiz-list",
    },
    {
      name: "Settings",
      url: "/profile",
    },
  ];
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/auth");
  };
  const [openForm, setOpenForm] = React.useState(false);
  return (
    <section className="h-full bg-purple text-white py-20 px-5 w-44 flex flex-col gap-5">
      {array.map((tab, index) => (
        <div key={index}>
          <Link to={tab.url} className="text-sm text-white font-roboto">
            {tab.name}
          </Link>
        </div>
      ))}
      <p
        className="cursor-pointer text-sm text-white font-roboto"
        onClick={() => setOpenForm(!openForm)}
      >
        Match
      </p>
      <a
        href={PrivacyPolicy}
        target="_blank"
        className="text-sm text-white font-roboto"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      <a
        href={TermsAndConditions}
        target="_blank"
        className="text-sm text-white font-roboto"
        rel="noopener noreferrer"
      >
        Terms & Conditions
      </a>
      <p
        className="cursor-pointer text-sm text-white font-roboto"
        onClick={handleLogout}
      >
        Logout
      </p>
      {openForm && <MatchPopUp setOpenForm={setOpenForm} openForm={openForm} />}
    </section>
  );
};

export default SideBar;
