import toast from "react-hot-toast";

const useToast = () => {
  const success = (message: string, description?: string) => {
    toast.success(message);
  };

  //   const warning = (message: string, description?: string) => {
  //     toast(message, {
  //         icon: ""
  //     })
  //   };

  const error = (message: string, description?: string) => {
    toast.error(message);
  };

  return { success, error };
};

export default useToast;
