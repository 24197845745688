import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import ModuleHeader from "../../components/ModuleHeader";
import WelletHeader from "../../components/WalletHeader";
import StepperQuestionForm from "../../components/StepperQuestionForm";
import Timer from "../../components/Timer";

import { getSingleDataFromSession, getSingleItem, storeQuestionAnsLocal } from "../../utils/localStorageService";
import { getAllQuestions, questionSubmit, quizEnd } from "../../services/quizService";
import { question_list } from "../../store/quize/quizeSlice";
import './QuizModule.css'
import CustomModal from "../../components/CustomModal";
import { PulseLoader } from "react-spinners";
import userService from "../../services/userService";
const QuestionList = () => {
    // todo: use this id to call getAllquestionApi and store them in LS
    const [sessionStarted, setSessionStarted] = useState(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const dispatch = useDispatch()
    const questionData = useSelector(question_list)
    const { id } = useParams<{ id: string }>();
    const [currIndex, setCurrIndex] = useState<number>(0)
    const naviagtion = useNavigate()
    const [quizDuration, setQuizDuration] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false);
    const sessionId = getSingleDataFromSession('quizSessionId')
    const [userData, setUserData] = useState<any>()
    const hanldeOnQuestionSubmit = async (data: any) => {
        await questionSubmit({
            quizId: id,
            sessionId: sessionId,
            questionId: data?.id,
            answer: data?.selectedOption
        });
        if (data.currentIndex === questionData.length && id) {
            await quizEnd({ id: id, sessionId: sessionId })
            setSessionStarted(false);

            naviagtion(`/result/${id}`)
        }
    }

    useEffect(() => {
        id && questionData?.length === 0 && setLoading(true)
        const getAllQuestion = async () => {
            id && await getAllQuestions(id, dispatch);
            setLoading(false)
            
        }
        id && questionData?.length === 0 && getAllQuestion()
        const duration = getSingleItem('duration');
        duration &&  console.log(parseInt(duration, 10))
        setQuizDuration(10)
        getUserData()
    }, [questionData, id])

 
    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (sessionStarted) {
                e.preventDefault(); // Standard for most browsers
                e.returnValue = ''; // Chrome requires returnValue to be set
                // setShowModal(true);
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
     
    }, [sessionStarted]);
    

    const hanldeExpireSession = () => {
        setShowModal(true)
        setSessionStarted(false)
    }
    const navigateToQuizPage = () =>{
            naviagtion('/quiz-list');
    }
    if(loading){
        return (<div className='m-40'> <PulseLoader   color='#7A54F0' loading={loading}/> </div>)
    }
    const getUserData = async()=>{
        setLoading(true)
        try {
             setLoading(false)
            const resp = await userService.getProfile()
            if(resp.status == 200){
                console.log(resp, 'user data')
                setUserData(resp.message)
            }
        } catch (error) {
             setLoading(false)
            console.log(error)
        }
    }
    return (
        <div>
              {showModal && (
                <CustomModal
                    modalTitle="Warning"
                    modalBody={<p>Your session is active. Are you sure you want to leave?</p>}
                    onClose={navigateToQuizPage}
                    outsideClose={true}
                    show={showModal}
                    required
                    singleButton
                />
            )}
            <div className="p-4 h-40 bg-white rounded-b-xl shadow-md">
                <div className="mb-4">
                    <ModuleHeader userData={userData} title="Quiz" onBack={() => { console.log('back') }} />
                </div>
                <div className="mb-4">
                    <WelletHeader />
                    <div className="flex justify-between mt-2 align-center">
                        <div className="font-roboto text-[24px] font-400 leading-[28.13px] tracking-[0.03em] text-left tracking-wider  text-purple">
                            Quiz 1
                        </div>
                        <div className="flex text-purple">
                            <Timer expiryTimestamp={30} label="Time Left" onExpire={hanldeExpireSession} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-lightestBlue w-100 question-list-question-wrapper">
                <StepperQuestionForm onFormSubmit={() => { }} onQuestionSubmit={hanldeOnQuestionSubmit} currIndex={currIndex} setCurrIndex={setCurrIndex} updatedQuestionData={[]}></StepperQuestionForm>
            </div>
        </div>);
};

export default QuestionList;
