import React from 'react'
import ArrowBack from '../assets/icons/arrowBack.svg'
import BellIcon from '../assets/icons/bell.svg'
import UserImage from '../assets/images/user-image.png'
import VerifiedImage from '../assets/icons/verified.svg'
import { useNavigate } from 'react-router-dom'

// -- types
interface Props {
    title: string;
    onBack: () => void
    userData:any
    redirectToHome?: boolean;
}

const ModuleHeader = (props: Props) => {
    // Props
    const naviagtion = useNavigate();
    const { title, onBack, userData, redirectToHome } = props

    const handleBackClick = (event: any) => {
        if(redirectToHome){
            naviagtion('/')
            onBack()
        }else{
            window.history.back()
            onBack()
        }
    }

    return (
        <div className="flex items-center justify-between">
            <div className="flex gap-3 items-center">
                <button className="flex items-center justify-center p-2 border border-greyText rounded-full" onClick={handleBackClick}>
                    <img src={ArrowBack} alt="arrow_back" className='w-3 h-3' />
                </button>
                <span className="font-roboto text-[24px] font-400 leading-[28.13px] tracking-[0.03em] text-left min-w-40">{title}</span>
            </div>

            <div className="flex gap-2 items-center relative">
                <img src={BellIcon} alt="bell_icon" className='w-6 h-6' />
                <img src={userData?.profilePic} alt="bell_icon" className='w-8 h-8 rounded-full' />
                <img src={VerifiedImage} alt="bell_icon" className='w-4 h-4 rounded-full absolute bottom-0 right-0' />
            </div>
        </div>
    )
}

export default ModuleHeader