// @ts-nocheck

import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../components/Logo";
import ActionButton from "../components/ActionButton";
import { Input } from "@mui/material";
import logo from "../assets/icons/verify-logo.svg";
import authService from "../services/authService";
import { LoaderCircle } from "lucide-react";
import { IoIosArrowDropleft } from "react-icons/io";
import COLORS from "../constants/Colors";
function ForgotPassword() {
  const type = process.env.REACT_APP_TYPE;
  const IP = process.env.REACT_APP_IP;
  const REACT_APP_PROD_SSL_SWITCH = process.env.REACT_APP_PROD_SSL_SWITCH;
const REACT_APP_PROD_SSL_PORT = process.env.REACT_APP_PROD_SSL_PORT;
  const BACKEND_URL =
    type == "dev" ? "http://localhost:5000" : `${REACT_APP_PROD_SSL_SWITCH}://${IP}:${REACT_APP_PROD_SSL_PORT}`;
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [isloading, setisloading] = useState(false);
  const [sent, setsent] = useState(false);

  const sendCode = async () => {
    setisloading(true);
    try {
      const res = await axios.post(`${BACKEND_URL}/v1/user/forgot/password`, {
        email,
      });

      toast.success(res.data.message);
      setisloading(false);
      setsent(true);
      navigate(`/verify/code/${encodeURIComponent(email)}`);
    } catch (err) {
      toast.error(err.response.data.message);
      setisloading(false);
    }
  };

  return (
    <div>
      <div className="flex flex-col min-h-screen my-8 mx-2 mr-2">
        <div className="flex flex-row items-center justify-between mr-6">
          <IoIosArrowDropleft
            size={34}
            color={COLORS.purple}
            onClick={() => navigate("/login")}
          />

          <p
            className={`font-roboto font-normal text-sm text-purple ${
              email.trim() ? " cursor-pointer" : "cursor-not-allowed"
            }`}
            onClick={() => {
              if (email.trim()) {
                navigate(`/verify/code/${encodeURIComponent(email)}`);
              }
            }}
          >
            Skip
          </p>
        </div>
        <div className="my-2">
          <img src={logo} className="w-[300px] mt-6 mx-6 justify-center" />
          <div className="bg-white p-2 mt-14 rounded-2xl">
            <div>
              <div className="text-2xl font-roboto font-normal  text-center">
                Change Password{" "}
              </div>
              <div className="mt-auto flex flex-col gap-y-3 w-full px-6 py-10">
                <div>
                  {" "}
                  <input
                    placeholder="Enter mail id"
                    className="bg-[#F2F6FA] border text-xs border-gray-400 rounded-xl mt-2 p-4 w-full"
                    onChange={(e) => setEmail(e.target.value)}
                  />{" "}
                </div>

                {!isloading ? (
                  <ActionButton
                    buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md mt-8 "
                    buttonText="Get Code"
                    onClick={sendCode}
                  />
                ) : (
                  <div className="bg-gradient-to-br p-4 flex justify-around from-purple to-lightPurple rounded-md">
                    {" "}
                    <LoaderCircle className="animate-spin text-white" />
                    <div className="text-white">Getting Code </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
