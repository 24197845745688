import { apiWithAccessToken } from "../config/apiConfig";
import { setQuestionList, setQuizeList, setSelectedQuize } from "../store/quize/quizeSlice";
import { storeSingleData } from "../utils/localStorageService";

export async function getAllQuizList(semester: number, dispatch: any) {
  try {
    const res = await apiWithAccessToken.get(`/quiz/all?semester=${semester}`)
    if (res?.data?.status === 200) {
      dispatch(setQuizeList(res.data.message))
      return true
    }else{
      console.error('Api error', res.data.message)
      return false
    }
  } catch (error: any) {
    console.error('Api error', error.message)
    return false
  }
}

export async function getQuizDetails(id: string, dispatch: any) {
  try {
    const res = await apiWithAccessToken.get(`/quiz?id=${id}`);
    if (res?.status === 200) {
      dispatch(setSelectedQuize(res?.data?.message))
      return true
    }
    return true
  } catch (error: any) {
    console.error('Api error', error.message)
    return false
  }
}

export async function quizStart(id: string) {
  try {
    const enter = await apiWithAccessToken.post(`/quiz/enter?quizId=${id}`)
    const res = await apiWithAccessToken.post(`/quiz/start?quizId=${id}`);
    if (res?.status === 200) {
      return res?.data
    }

  } catch (error: any) {
    console.error('Api error', error.message)
    return false
  }
};

export async function getAllQuestions(id: string, dispatch: any) {
  try {
    const res = await apiWithAccessToken.get(`/question/all?quizId=${id}`);
    if (res.status === 200) {
      dispatch(setQuestionList(res?.data?.message?.questions))
      storeSingleData({ data: res?.data?.message?.duration, key: "duration" })
      return true
    }
  } catch (error: any) {
    console.error("Api error", error.message)
    return false
  }
}

export async function questionSubmit(data: Object, dispatch?: any) {
  try {
    const res = await apiWithAccessToken.post(`/question/submit`, data)
  } catch (error: any) {
    console.error("Api error", error.message)
    return false
  }
}
export async function getResults(id: string, sessionId: string) {
  try {
    const res = await apiWithAccessToken.get(`/question/answers?quizId=${id}&sessionId=${sessionId}`)
    return res
  } catch (error) {
    console.log(error)
  }
}

export async function getLeaderboardData(id: string) {
  try {
    const res = await apiWithAccessToken.get(`/score/all?quizId=${id}`)
    return res
  } catch (error) {
    console.log(error)
  }
}

export async function quizEnd({ id, sessionId }: { id: string, sessionId: string }) {
  try {
    const res = await apiWithAccessToken.post(`/quiz/end?quizId=${id}&sessionId=${sessionId}`)
    if (res.status === 200) {
      return true
    } else {
      return false
    }
  } catch (error: any) {
    console.error('API ERROR', error.message)
    return false
  }
}

export async function deleteQuiz(id: string) {
  try {
    const resp = await apiWithAccessToken.delete(`/quiz?id=${id}`)
    console.log(resp)
  } catch (error) {
    console.log(error)
  }
}