import React, { useState } from "react";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import { NavLink } from "react-router-dom";
import { IconButton, Avatar, TextField, Button } from "@mui/material";
import Post from "../components/Post";
import PostPop from "../components/popup/PostPop";
import PostInputPop from "../components/popup/PostInputPop";

import { useForum } from "../utils/Forum";
import Heading from "../components/Heading";

const Forum = () => {
  const [open, setOpen] = useState(false);
  const [currPost, setCurrPost] = useState();

  const [openForm, setOpenForm] = useState(false);
  const { posts, handleGetCurrPost } = useForum();

  const array = [1, 2, 3, 4];
  return (
    <div className="py-[0.5rem]  text-md flex flex-col gap-5">
      <Heading title="Forum" />
      <div className="w-full mt-16 px-[1rem]">
        <div
          id="outlined-basic"
          className="w-full px-5 py-3 border rounded-md text-xs text-greyText bg-lightBlue"
          onClick={() => setOpenForm(!open)}
        >
          What do you want to ask or share?
        </div>
        {/* <div className="flx-row justify-between">
          <span className="text-sm flx-row ">
            <IconButton>
              <LiveHelpOutlinedIcon className="text-xs" />
            </IconButton>
            Ask
          </span>
          <span className="text-sm flx-row ">
            <IconButton>
              <EditNoteOutlinedIcon className="text-sm" />
            </IconButton>
            Answer
          </span>
          <span className="text-sm flx-row ">
            <IconButton>
              <ModeEditOutlineOutlinedIcon className="text-xs" />
            </IconButton>
            Post
          </span>
        </div> */}
      </div>
      <div className="flex-col gap-5 flex px-[1rem]">
        {posts &&
          posts.length > 0 &&
          posts?.map((item: any,id) => (
            <div
              key={id}
              onClick={async () => {
                setCurrPost(await handleGetCurrPost(item?.id));
                setOpen(!open);
              }}
            >
              <Post item={item} />
            </div>
          ))}
      </div>
      {open && <PostPop open={open} setOpen={setOpen} currPost={currPost} />}
      {openForm && (
        <PostInputPop openForm={openForm} setOpenForm={setOpenForm} />
      )}
    </div>
  );
};

export default Forum;
