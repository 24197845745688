import React, { useState } from "react";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import { NavLink } from "react-router-dom";
import { IconButton, Avatar, TextField, Button } from "@mui/material";
import Post from "../Post";
import { useForum } from "../../utils/Forum";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useAuth } from "../../context/AuthContext";
const style = {
  position: "absolute" as "absolute",
  top: "10%",
  width: "100%",
  height: "90%",
  bgcolor: "background.paper",
  borderRadius: "20px 20px 0px 0px",
};
type PopType = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currPost: any;
};

const PostPop = ({ open, setOpen, currPost }: PopType) => {
  const [comment, setComment] = useState("");
  const { handleComment, handleConnectFriend } = useForum();
  const [openReply, setOpenReply] = useState(null);
  const [reply, setReply] = useState("");
  const [openComment, setOpenComment] = useState<string[]>([]);

  const handleDate = (date: string) => {
    const today = new Date(date);
    const month = today.toLocaleString("default", { month: "long" });
    return month + " " + today.getDate();
  };
  const { account } = useAuth();
  // console.log("Current Post", currPost);

  const getReplies = (parentId: string) => {
    return (
      <div className="flex flex-col gap-5 ">
        {currPost?.comments
          ?.filter((item: any) => item.parentId == parentId)
          .map((item: any) => (
            <div className="flex flex-col gap-5" key={item?.id}>
              <div className="flex flex-col gap-5">
                <div className="flex flex-row justify-between  ">
                  <div className="flex-1 flx-row gap-3">
                    <Avatar alt="Remy Sharp" src={item?.user?.profilePic} />
                    <div className="flex flex-col ">
                      <p className="font-medium text-base text-darkgrey">
                        {item?.user?.name}
                      </p>
                      <p className="text-xs text-greyText">
                        College Name Third Year
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col text-right  text-purple text-xs">
                    {account && (
                      <NavLink
                        className={`${
                          item?.userId != account.id
                            ? "text-purple cursor-pointer"
                            : "text-greyText cursor-default"
                        } `}
                        to={
                          account
                            ? item?.userId != account.id
                              ? `/chat/${item?.userId}`
                              : ""
                            : ""
                        }
                      >
                        Chat
                      </NavLink>
                    )}{" "}
                  </div>
                </div>
                <div className="flx-row justify-between gap-3 text-xs font-normal text-darkgrey">
                  <p>{item?.comment}</p>
                  <p>{handleDate(item?.createdAt)}</p>
                </div>
                <div className="flx-row space-x-5">
                  <button
                    className="text-xs font-normal text-purple  w-fit"
                    onClick={() => setOpenReply(item?.id)}
                  >
                    Reply
                  </button>
                  {currPost?.comments.some(
                    (comment: any) => comment.parentId === item.id
                  ) && (
                    <button
                      className="text-xs font-normal text-purple w-fit"
                      onClick={() => {
                        if (openComment.includes(item?.id)) {
                          setOpenComment(
                            openComment.filter((i) => i !== item?.id)
                          );
                        } else {
                          setOpenComment([...openComment, item?.id]);
                        }
                      }}
                    >
                      Comments
                    </button>
                  )}
                </div>
                <div className="px-1 w-full max-w-screen-lg mx-auto">
                  {openComment.includes(item?.id) && getReplies(item?.id)}
                </div>
                {/* {openReply===item?.id && getReplyField(item?.id)} */}
                {openReply === item?.id && (
                  <span className="flx-row justify-between py-3 px-2 rounded-lg border-greyText border-2">
                    <input
                      type="text"
                      placeholder="Reply Comment"
                      className="outline-none flex-1 text-sm"
                      value={reply}
                      onChange={(e) => {
                        setReply(e.target.value);
                      }}
                    />
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() =>
                        handleComment(currPost?.id, reply, item?.id)
                      }
                    >
                      <path
                        d="M1.0492 5.56594C0.537911 2.89996 2.87846 0.5594 5.54444 1.07069L14.6935 2.82531C16.3954 3.1517 17.7599 4.44156 18.1933 6.11937C19.0591 9.47073 15.9496 12.5202 12.6288 11.5436C11.952 11.3445 11.3231 11.9734 11.5221 12.6503C12.4987 15.9711 9.44925 19.0805 6.0979 18.2148C4.4201 17.7814 3.13024 16.4168 2.80385 14.715L1.0492 5.56594Z"
                        stroke="#7A54F0"
                        stroke-width="1.5"
                      />
                    </svg>
                  </span>
                )}
              </div>
              {/* <div className="flx-row text-xs gap-5">
                <span>View 31</span>
                <span>Likes {item?.likes}</span>
                <span>Comments 05</span>
              </div> */}
            </div>
          ))}
      </div>
    );
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="flex flex-col gap-5  ">
          <div className="flx-row justify-between sticky top-0 bg-white rounded-xl px-5 pt-5">
            <p className="font-semibold">Name of the topic</p>
            <Button
              variant="contained"
              size="small"
              className="w-20 h-6 bg-purpleGradient"
              onClick={() => {
                handleConnectFriend({ friendId: currPost?.userId });
              }}
              disabled={currPost?.userId === account?.id}
            >
              Connect
            </Button>
          </div>
          <div className="py-5 px-2 overflow-y-auto no-scroll flex flex-col gap-5 ">
            <Post item={currPost} />
            <div className="flex flex-row gap-3 ">
              <div className="">
                <Avatar
                  alt="Profile"
                  src={
                    (account && account.profilePic) ||
                    "/static/images/avatar/1.jpg"
                  }
                  sx={{ width: 29, height: 29 }}
                  className="translate-y-3"
                />
              </div>
              <div className="flex flex-col gap-7 flex-1">
                <span className="flx-row justify-between py-3 px-2 rounded-lg border-greyText border-2">
                  <input
                    type="text"
                    placeholder="Add Comment"
                    className="outline-none flex-1 text-sm"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      handleComment(currPost?.id, comment);
                    }}
                  >
                    <path
                      d="M1.0492 5.56594C0.537911 2.89996 2.87846 0.5594 5.54444 1.07069L14.6935 2.82531C16.3954 3.1517 17.7599 4.44156 18.1933 6.11937C19.0591 9.47073 15.9496 12.5202 12.6288 11.5436C11.952 11.3445 11.3231 11.9734 11.5221 12.6503C12.4987 15.9711 9.44925 19.0805 6.0979 18.2148C4.4201 17.7814 3.13024 16.4168 2.80385 14.715L1.0492 5.56594Z"
                      stroke="#7A54F0"
                      stroke-width="1.5"
                    />
                  </svg>
                </span>
                <div className="flex-col flex gap-3 ">
                  {currPost?.comments
                    ?.filter((item: any) => item.parentId == null)
                    .map((item: any) => (
                      <div className="flex flex-col gap-5" key={item?.id}>
                        <div className="flex flex-col gap-5">
                          <div className="flex flex-row justify-between  ">
                            <div className="flex-1 flx-row gap-3">
                              <Avatar
                                alt="Remy Sharp"
                                src={item?.user?.profilePic}
                              />
                              <div className="flex flex-col ">
                                <p className="font-medium text-base text-darkgrey">
                                  {item?.user?.name}
                                </p>
                                <p className="text-xs text-greyText">
                                  College Name Third Year
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-col text-right  text-purple text-xs">
                              {account && (
                                <NavLink
                                  className={`${
                                    item?.userId != account.id
                                      ? "text-purple cursor-pointer"
                                      : "text-greyText cursor-default"
                                  } `}
                                  to={
                                    account
                                      ? item?.userId != account.id
                                        ? `/chat/${item?.userId}`
                                        : ""
                                      : ""
                                  }
                                >
                                  Chat
                                </NavLink>
                              )}{" "}
                            </div>
                          </div>
                          <div className="flx-row justify-between gap-3 text-xs font-normal text-darkgrey">
                            <p>{item?.comment}</p>
                            <p>{handleDate(item?.createdAt)}</p>
                          </div>
                          <div className="flx-row space-x-5">
                            <button
                              className="text-xs font-normal text-purple  w-fit"
                              onClick={() => {
                                if (openReply === item?.id) {
                                  setOpenReply(null);
                                } else {
                                  setOpenReply(item?.id);
                                }
                              }}
                            >
                              Reply
                            </button>
                            {currPost?.comments.some(
                              (comment: any) => comment.parentId === item.id
                            ) && (
                              <button
                                className="text-xs font-normal text-purple w-fit"
                                onClick={() => {
                                  if (openComment.includes(item?.id)) {
                                    setOpenComment(
                                      openComment.filter((i) => i !== item?.id)
                                    );
                                  } else {
                                    setOpenComment([...openComment, item?.id]);
                                  }
                                }}
                              >
                                Comments
                              </button>
                            )}
                          </div>
                          <div className="px-1 w-full max-w-screen-lg mx-auto">
                            {openComment.includes(item?.id) &&
                              getReplies(item?.id)}
                          </div>
                          {/* {openReply === item?.id && getReplyField(item?.id)} */}
                          {openReply === item?.id && (
                            <span className="flx-row justify-between py-3 px-2 rounded-lg border-greyText border-2">
                              <input
                                type="text"
                                placeholder="Reply Comment"
                                className="outline-none flex-1 text-sm"
                                value={reply}
                                onChange={(e) => {
                                  setReply(e.target.value);
                                }}
                              />
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                                onClick={() => {
                                  console.log("Reply", reply);
                                  handleComment(currPost?.id, reply, item?.id);
                                  setReply("");
                                }}
                              >
                                <path
                                  d="M1.0492 5.56594C0.537911 2.89996 2.87846 0.5594 5.54444 1.07069L14.6935 2.82531C16.3954 3.1517 17.7599 4.44156 18.1933 6.11937C19.0591 9.47073 15.9496 12.5202 12.6288 11.5436C11.952 11.3445 11.3231 11.9734 11.5221 12.6503C12.4987 15.9711 9.44925 19.0805 6.0979 18.2148C4.4201 17.7814 3.13024 16.4168 2.80385 14.715L1.0492 5.56594Z"
                                  stroke="#7A54F0"
                                  stroke-width="1.5"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                        {/* <div className="flx-row text-xs gap-5">
                        <span>View 31</span>
                        <span>Likes {item?.likes}</span>
                        <span>Comments 05</span>
                      </div> */}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default PostPop;
