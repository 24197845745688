import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Auth from "./pages/Auth";
import Register from "./pages/Register";
import GuestRoutes from "./routes/GuestRoutes";
import AuthRoutes from "./routes/AuthRoutes";
import Home from "./pages/Home";
import Forum from "./pages/Forum";

import Room from "./pages/Chat/Room";
import Chats from "./pages/Chat/Chats";
import CreateRoom from "./pages/Chat/CreateRoom";
import Chat from "./pages/Chat/Chat";
import GroupChat from "./pages/Chat/GroupChat";

import TabLayout from "./layouts/TabLayout";
import Profile from "./pages/Profile";
import Onboarding from "./pages/Onboarding";
import Kyc from "./pages/Kyc";
import Interest from "./pages/Interest";
import Location from "./pages/Location";
import GoogleLogin from "./pages/GoogleLogin";
// import Match from "./pages/Match";
import Meet from "./components/Meeting";
import MatchUser from "./pages/Matching";
import OtpVerfiy from "./pages/OtpVerfiy";
import QuizList from "./pages/quiz/QuizList";
import QuizDetails from "./pages/quiz/QuizDetails";
import QuestionList from "./pages/quiz/QuestionList";
import LeaderBoard from "./pages/quiz/LeaderBoard";
import Result from "./pages/quiz/Result";
import { useNavigate } from "react-router-dom";
import RedirectGroup from "./pages/RedirectGroup";

import ForgotPassword from "./pages/ForgotPassword";
import VerifyCode from "./pages/VerifyCode";
import ResetPassword from "./pages/ResetPassword";

import Match from "./pages/Match/Match";

function App() {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;
  const navigate = useNavigate();
  function handleWindowResize() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return isMobile ? (
    <Routes>
      <Route element={<GuestRoutes />}>
        <Route path="/auth" element={<Auth />} />
        <Route path="/login" element={<Login />} />
        <Route path="/google-login" element={<GoogleLogin />} />
        <Route path="/register" element={<Register />} />{" "}
        <Route
          path="/otp/:mail/:name/:password/:gender"
          element={<OtpVerfiy />}
        />
        <Route path="/forgot/password" element={<ForgotPassword />} />
        <Route path="/verify/code/:mail" element={<VerifyCode />} />
        <Route path="/reset/password/:mail" element={<ResetPassword />} />
      </Route>
      <Route element={<AuthRoutes />}>
        <Route element={<TabLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/forum" element={<Forum />} />
          <Route path="/quiz-list" element={<QuizList />} />
          <Route path="/quiz-details/:id" element={<QuizDetails />} />
          <Route path="/quiz-questions/:id" element={<QuestionList />} />
          <Route path="/leaderboard/:id" element={<LeaderBoard />} />
          <Route path="/result/:id" element={<Result />} />
          {/* <Route path="/connect" element={<Match />} />{" "} */}
          <Route path="/matching/:topic" element={<Match />} />
          <Route path="/meet" element={<Meet />} />v
          <Route path="/room" element={<Room />} />
          <Route path="/chat" element={<Chats />} />
          <Route path="/create-room" element={<CreateRoom />} />
        </Route>
        <Route path="/profile" element={<Profile />} />
        {/* <Route path="/edit-profile" element={<EditProfile />} /> */}
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/kyc" element={<Kyc />} />
        <Route path="/interest" element={<Interest />} />
        <Route path="/location" element={<Location />} />
        <Route path="/join/:token" element={<RedirectGroup />} />
        <Route path="/chat/:chatId" element={<Chat />} />
        <Route path="/room/:roomId" element={<GroupChat />} />
        <Route path="/coming-soon" element={<div>Coming Soon</div>} />
      </Route>
    </Routes>
  ) : (
    <div>The content is only visible on mobile screens</div>
  );
}

export default App;
