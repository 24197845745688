// @ts-nocheck

import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../components/Logo";
import ActionButton from "../components/ActionButton";
import { Input } from "@mui/material";
import logo from "../assets/icons/verify-logo.svg";
import authService from "../services/authService";
import { LoaderCircle } from "lucide-react";
import { IoIosArrowDropleft } from "react-icons/io";
import COLORS from "../constants/Colors";
function ResetPassword() {
  const type = process.env.REACT_APP_TYPE;
  const IP = process.env.REACT_APP_IP;
  const REACT_APP_PROD_SSL_SWITCH = process.env.REACT_APP_PROD_SSL_SWITCH;
const REACT_APP_PROD_SSL_PORT = process.env.REACT_APP_PROD_SSL_PORT;
  const BACKEND_URL =
    type == "dev" ? "http://localhost:5000" : `${REACT_APP_PROD_SSL_SWITCH}://${IP}:${REACT_APP_PROD_SSL_PORT}`;
  //   const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isResetLoading, setIsResetLoading] = useState(false);

  const [sent, setsent] = useState(false);
  const { mail } = useParams();
  //   useEffect(() => {
  //     setEmail(decodeURIComponent(mail));
  //     console.log("Mail", email);
  //   }, []);
  const updatePassword = async () => {
    setIsResetLoading(true);
    try {
      const res = await axios.post(`${BACKEND_URL}/v1/user/reset/password`, {
        email: mail,
        newPassword: password,
      });

      toast.success(res.data.message);
      setIsResetLoading(false);
      setsent(true);
      navigate("/login");
    } catch (err) {
      toast.error(err.response.data.message);
      setIsResetLoading(false);
    }
  };

  return (
    <div>
      <div className="flex flex-col min-h-screen my-8 mx-2 mr-2">
        <div className="flex flex-row items-center justify-between mr-6">
          <IoIosArrowDropleft
            size={34}
            className={` ${
              mail.trim() ? " cursor-pointer" : "cursor-not-allowed"
            }`}
            color={COLORS.purple}
            onClick={() => {
              if (mail.trim()) {
                navigate(`/verify/code/${encodeURIComponent(mail)}`);
              }
            }}
          />
          <Link to="/login">
            <p className="font-roboto font-normal text-sm text-purple">Skip</p>
          </Link>
        </div>
        <div className="my-2">
          <img src={logo} className="w-[300px] mt-6 mx-6 justify-center" />
          <div className="bg-white p-2 mt-14 rounded-2xl">
            <div>
              <div className="text-2xl font-roboto font-normal  text-center">
                New Password{" "}
              </div>
              <div className="mt-auto flex flex-col gap-y-3 w-full px-6 py-10">
                <div>
                  {" "}
                  <input
                    type="password"
                    placeholder="Enter Password"
                    className="bg-[#F2F6FA] border text-xs border-gray-400 rounded-xl mt-2 p-4 w-full"
                    onChange={(e) => setPassword(e.target.value)}
                  />{" "}
                </div>
                <div>
                  {" "}
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    className="bg-[#F2F6FA] border text-xs border-gray-400 rounded-xl mt-2 p-4 w-full"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />{" "}
                </div>

                {!isResetLoading ? (
                  <ActionButton
                    buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md mt-8 "
                    buttonText="Update"
                    onClick={updatePassword}
                  />
                ) : (
                  <div className="bg-gradient-to-br p-4 flex justify-between from-purple to-lightPurple rounded-md">
                    {" "}
                    <LoaderCircle className="animate-spin text-white" />
                    <div className="text-white">Updating</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
