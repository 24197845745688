import React, { useState, useEffect, useCallback } from "react";
import FailMatch from "../assets/images/fail-match.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";

import { useAuth } from "../context/AuthContext";

type PopType = {
  openForm: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
};

const MatchUser = ({setOpenForm ,openForm}:PopType) => {
  const [message, setMessage] = useState<string>("");
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>();
  const [matchFail, setMatchFail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { account } = useAuth();
  const gender = ["male", "female", "not-specified"];
  const navigate = useNavigate();
  const handleMatch = useCallback(() => {

    if (account && selectedOption) {
      console.log(process.env.REACT_APP_API_URL,"Url for connection");

      const socket = io(`${process.env.REACT_APP_API_URL}`);
  
      setLoading(true);
  
      socket.emit("find-match", {
        id: account.id,
        topic: message,
        gender: selectedOption,
      });
  
      const timeout = setTimeout(() => {
        console.log("No match found within 30 seconds, disconnecting...");
        socket.disconnect();
        setMatchFail(true);
      }, 30000); 
  
      socket.on("match-found", (data) => {
        clearTimeout(timeout); 
        console.log("The best matches", data);
        const userId = data[0].id === account.id ? data[1].id : data[0].id;
        setOpenForm(false);
        navigate("/matching/" + userId);
        socket.disconnect();
      });
  
      socket.on("connect_error", (error) => {
        clearTimeout(timeout); 
        console.log("Socket connection error:", error);
        socket.disconnect();
        setMatchFail(true);
      });
  
      return () => {
        clearTimeout(timeout); 

        socket.disconnect();
      };
    }
  }, [account, selectedOption, message, navigate]);
  return (
    <div className="p-5  h-full flx-row">
      {isFilled ? (
        matchFail ? (
          <div className="flex flex-col items-center justify-center mx-auto w-full gap-5">
            <img src={FailMatch} alt="fail-match" className="h-28" />
            <p>Sorry! We couldn’t find a match for you.</p>
            <button
              className=" text-white cursor-pointer w-full bg-purpleGradient p-2 rounded-md"
              onClick={() => {
                setIsFilled(false);
                setMatchFail(false);
                setSelectedOption("");
                setLoading(false);
              }}
            >
              Search Again
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-center  gap-10 mx-auto justify-center">
            <p className="text-darkgrey font-semibold ">I am interested in</p>

            <div className="flx-row gap-4 justify-between ">
              {gender.map((item) => (
                <button
                  className={`px-5 py-[0.5px] rounded-lg border ${
                    selectedOption === item
                      ? "bg-purpleGradient text-white"
                      : ""
                  }`}
                  id={item}
                  onClick={(e: any) => {
                    if (e.target.id === selectedOption) {
                      setSelectedOption("");
                    } else {
                      setSelectedOption(e.target.id);
                    }
                  }}
                >
                  {item}
                </button>
              ))}
            </div>
            <button
              className=" text-white cursor-pointer w-full bg-purpleGradient p-2 rounded-md flx-row gap-2 justify-center"
              onClick={() => {
                if (selectedOption) {
                  handleMatch();
                }
              }}
            >
              {loading ? (
                <>
                  Matching
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8V12H4z"
                    ></path>
                  </svg>
                </>
              ) : (
                "Match"
              )}
            </button>
          </div>
        )
      ) : (
        <div className="flex flex-col text-center w-full mx-auto  gap-5">
          <p className="text-darkgrey font-semibold mb-5">
            Write the topic you want to search{" "}
          </p>

          <div className="flx-row gap-2 px-5 py-2 w-full  bg-[#F2F6FA] border border-gray-400  rounded-full">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5233 11.4628L15.7355 14.6742L14.6742 15.7355L11.4628 12.5233C10.2678 13.4812 8.7815 14.0022 7.25 14C3.524 14 0.5 10.976 0.5 7.25C0.5 3.524 3.524 0.5 7.25 0.5C10.976 0.5 14 3.524 14 7.25C14.0022 8.7815 13.4812 10.2678 12.5233 11.4628ZM11.0187 10.9062C11.9706 9.92741 12.5022 8.61532 12.5 7.25C12.5 4.34975 10.1503 2 7.25 2C4.34975 2 2 4.34975 2 7.25C2 10.1503 4.34975 12.5 7.25 12.5C8.61532 12.5022 9.92741 11.9706 10.9062 11.0187L11.0187 10.9062Z"
                fill="#99A9D1"
              />
            </svg>

            <input
              type="text"
              placeholder="enter your topic"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              className="bg-transparent outline-none flex-1 p-1"
            />
          </div>
          <h1 className="text-purple text-3xl">Happy studying!</h1>
          <button
            className=" text-white cursor-pointer w-full bg-purpleGradient p-2 rounded-md"
            onClick={() => {
              if (message.length > 0) {
                setIsFilled(true);
              }
            }}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default MatchUser;
