import React from "react";
import { IoIosArrowDropleft } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import COLORS from "../constants/Colors";
import ActionButton from "../components/ActionButton";
import userService from "../services/userService";
import useToast from "../hooks/useToast";

const likes = [
  {
    label: "Photography",
    value: "photography",
    isMarked: false,
  },
  {
    label: "Cooking",
    value: "cooking",
    isMarked: false,
  },
  {
    label: "Video Games",
    value: "video_games",
    isMarked: false,
  },
  {
    label: "Music",
    value: "music",
    isMarked: false,
  },
  {
    label: "Traveelling",
    value: "travelling",
    isMarked: false,
  },
  {
    label: "Shopping",
    value: "shopping",
    isMarked: false,
  },
  {
    label: "Speeches",
    value: "speeches",
    isMarked: false,
  },
  {
    label: "Art & Crafts",
    value: "art_and_crafts",
    isMarked: false,
  },
  {
    label: "Swimming",
    value: "swimming",
    isMarked: false,
  },
  {
    label: "Drinking",
    value: "drinking",
    isMarked: false,
  },
  {
    label: "Sports",
    value: "sports",
    isMarked: false,
  },
  {
    label: "Fitness",
    value: "fitness",
    isMarked: false,
  },
];

const Interest = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [interests, setInterests] = React.useState(likes);
  const [selectedInterests, setSelectedInterests] = React.useState<string[]>(
    []
  );

  const toggleInterest = (value: string) => {
    const newInterests = interests.map((interest) => {
      if (interest.value === value) {
        interest.isMarked = !interest.isMarked;
        setSelectedInterests([...selectedInterests, value]);
      }
      return interest;
    });

    setInterests(newInterests);
  };

  const updateInterests = async () => {
    try {
      if(selectedInterests.length === 0) {
        toast.error("Please select at least one interest");
        return;
      }
      console.log(selectedInterests, "selected interests");
      const res = await userService.updateUser({
        titles: selectedInterests,
      });
      console.log(res, "update interests");
      toast.success("Interests updated successfully");
      navigate("/location");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="flex flex-col min-h-screen my-8 mx-4">
      <div className="flex flex-row items-center justify-between gap-x-3">
        <IoIosArrowDropleft
          size={24}
          color={COLORS.purple}
          onClick={() => navigate("/kyc")}
        />
        <Link to="/interest">
          <p className="font-inter text-lg text-purple">Skip</p>
        </Link>
      </div>
      <div className="my-5">
        <h4 className="font-inter text-xl text-center">
          Fill Likes & Interests
        </h4>
        <p className="text-greyText font-roboto font-medium text-center mt-2">
          Share your likes & passion with others
        </p>
      </div>
      <div className="flex flex-wrap gap-2">
        {interests.map((interest) => (
          <div
            className={`p-4 w-[48%] border-2 border-lightBlue bg-lightBlue rounded-lg ${
              interest.isMarked && "border-purple"
            }`}
            onClick={() => toggleInterest(interest.value)}
          >
            <p className="text-purple font-roboto font-medium">
              {interest.label}
            </p>
          </div>
        ))}
      </div>
      <div className="mt-8">
        <ActionButton
          buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md"
          buttonText="Continue"
          onClick={updateInterests}
        />
      </div>
    </div>
  );
};

export default Interest;
