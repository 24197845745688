import React from 'react';
import { useTimer } from 'react-timer-hook';

interface TimerProps {
    expiryTimestamp: number | string;
    label?: string;
    onExpire?: () =>void;
}

export default function Timer({ expiryTimestamp, label, onExpire }: TimerProps) {
    const getExpiryDate = (): Date => {
        if (typeof expiryTimestamp === 'number') {
            // If expiryTimestamp is a number, treat it as a duration in minutes
            const now: Date = new Date();
            return new Date(now.getTime() + expiryTimestamp * 60000); // 60000 ms in a minute
        } else {
            // If expiryTimestamp is a string, treat it as a specific date
            return new Date(expiryTimestamp);
        }
    };

    const expireDate = getExpiryDate();
    const { seconds, minutes } = useTimer({ expiryTimestamp: expireDate , onExpire:onExpire});
  

    return (
        <div className="flex items-center">
            <span className="mr-2 font-roboto text-[16px] font-500 leading-[18.75px] text-left">{label}</span>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12.4596 21.32C17.3529 21.32 21.3196 17.3532 21.3196 12.46C21.3196 7.56673 17.3529 3.59998 12.4596 3.59998C7.56637 3.59998 3.59961 7.56673 3.59961 12.46C3.59961 17.3532 7.56637 21.32 12.4596 21.32Z"
                        stroke="#99A9D1"
                        strokeWidth="1.2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M12.46 7.62V12.46L15.44 13.88"
                        stroke="#99A9D1"
                        strokeWidth="1.2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </span>
            <span className="ml-1 font-roboto text-[16px] font-500 leading-[18.75px] text-left">{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</span>
        </div>
    );
}
