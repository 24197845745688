import React, { useState,useCallback } from "react";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import { NavLink } from "react-router-dom";
import { IconButton, Avatar, TextField, Button } from "@mui/material";
import toast from "react-hot-toast";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { useForum } from "../../utils/Forum";
import { useDropzone } from "react-dropzone";

const style = {
  position: "absolute" as "absolute",
  top: "10%",
  width: "100%",
  height: "90%",
  bgcolor: "background.paper",
  borderRadius: "20px 20px 0px 0px",
};
type PopType = {
  openForm: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
};
const type = process.env.REACT_APP_TYPE;
const IP = process.env.REACT_APP_IP;
const REACT_APP_PROD_SSL_SWITCH = process.env.REACT_APP_PROD_SSL_SWITCH;
const REACT_APP_PROD_SSL_PORT = process.env.REACT_APP_PROD_SSL_PORT;
const BACKEND_URL =
  type == "dev" ? "http://localhost:5000" : `${REACT_APP_PROD_SSL_SWITCH}://${IP}:${REACT_APP_PROD_SSL_PORT}`;
// const BACKEND_URL = "http://localhost:5000";

const PostInputPop = ({ openForm, setOpenForm }: PopType) => {
  const { formInputSource, handleCreatePost,image, setImage,description, setDescription } = useForum();


  async function getS3SignUrl(filename: string, filetype: string) {
    const headers = new Headers({ "Content-Type": "application/json" });
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ fileName: filename, fileType: filetype }),
    };
    const response = await fetch(`${BACKEND_URL}/v1/user/uploadImage`, options);
    const presignedUrl = await response.json();
    return presignedUrl;
  }
  async function pushProfilePhotoToS3(presignedUrl: string, file: File) {
    const myHeaders = new Headers({ "Content-Type": file.type });
    const response = await fetch(presignedUrl, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    });
    return response;
  }
  function getUrlUpToExtension(url: string) {
    const queryParamIndex = url.indexOf("?");
    const endIndex = queryParamIndex !== -1 ? queryParamIndex : url.length;
    return url.substring(0, endIndex);
  }
  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try{
      const file = acceptedFiles[0];
      // setImage(file);
      const data = await getS3SignUrl(file.name, file.type);
      console.log(data, "data");
      if (data.url) {
        const response = await pushProfilePhotoToS3(data.url, file);
        if (response) {
          console.log("Image Saved");
          // toast.success("Profile photo updated successfully");
          // handleFileUpload(getUrlUpToExtension(response.url));
          setImage(getUrlUpToExtension(response.url));
          // navigate("/interest");
          // setupload(true);
          // setFileLink(getUrlUpToExtension(response.url));
        } else {
          console.error("Upload failed");
          toast.error("Upload failed");
          // setupload(false);
        }
      }}catch(e){
        console.log(e);
        toast.error("Upload failed");
      }
    },
    [image]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': [] // Accepts all image file types
    }
  });
  
  
  return (
    <div>
      <Modal
        open={openForm}
        onClose={() => setOpenForm(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="flex flex-col gap-5  ">
          <div className="flx-row justify-between sticky top-0 bg-white rounded-xl px-5 pt-5">
            <p className="font-semibold">Create Post</p>
            <Button
              variant="contained"
              size="small"
              className="w-14 h-6 bg-purpleGradient"
              onClick={()=>{
                  handleCreatePost(image)}}
            >
              POST
            </Button>
          </div>
          <div className="p-5 overflow-y-auto no-scroll flex flex-col gap-5 ">
            {formInputSource.map((item, id) => (
              <input
                key={id}
                {...item}
                id="outlined-basic"
                required
                className="w-full border-b-2  p-2 outline-none"
              />
            ))}
            <textarea 
              className="w-full border-b-2 p-2 outline-none" 
              rows={4} 
              cols={50}
              placeholder="Set Description"
              value={description}
              onChange={(e)=>setDescription(e.target.value)}
            />

            {/*
            <Button variant="contained" className="w-32 h-10" >
              Post
            </Button> */}
            <div className="flx-row justify-end gap-2">
              <span className="text-sm text-greyText" {...getRootProps()}>
                <input {...getInputProps()}/>
                {image?image:'Add Photo'}</span>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1296_3694)">
                  <path
                    d="M13.0001 6.80989L7.05008 12.8099C6.81922 13.0451 6.54378 13.232 6.23987 13.3595C5.93596 13.4871 5.60967 13.5528 5.28008 13.5528C4.95048 13.5528 4.6242 13.4871 4.32029 13.3595C4.01637 13.232 3.74094 13.0451 3.51008 12.8099L1.73008 10.9999C1.2641 10.5266 1.00293 9.88906 1.00293 9.22489C1.00293 8.56071 1.2641 7.92317 1.73008 7.44989L8.07008 1.08989C8.256 0.902431 8.47721 0.753642 8.72092 0.652105C8.96464 0.550568 9.22605 0.498291 9.49008 0.498291C9.7541 0.498291 10.0155 0.550568 10.2592 0.652105C10.503 0.753642 10.7242 0.902431 10.9101 1.08989L11.6201 1.79989C11.8075 1.98581 11.9563 2.20702 12.0579 2.45073C12.1594 2.69445 12.2117 2.95586 12.2117 3.21989C12.2117 3.48391 12.1594 3.74532 12.0579 3.98904C11.9563 4.23276 11.8075 4.45396 11.6201 4.63989L6.00008 10.2799C5.90711 10.3736 5.79651 10.448 5.67465 10.4988C5.5528 10.5495 5.42209 10.5757 5.29008 10.5757C5.15807 10.5757 5.02736 10.5495 4.9055 10.4988C4.78364 10.448 4.67304 10.3736 4.58008 10.2799L4.23008 9.91989C4.13635 9.82692 4.06195 9.71632 4.01119 9.59446C3.96042 9.47261 3.93428 9.3419 3.93428 9.20989C3.93428 9.07788 3.96042 8.94717 4.01119 8.82531C4.06195 8.70345 4.13635 8.59285 4.23008 8.49989L8.00008 4.75989"
                    stroke="#7A54F0"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1296_3694">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default PostInputPop;
