import React, { useState, useCallback, useMemo, useEffect } from "react";
import toast from "react-hot-toast";
import { userPostApis} from "../services/forumService";
import {api} from "../config/apiConfig";

export const useForum = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [source, setSource] = useState();
  const [image, setImage] = useState<string | null>(null);

  const formInputSource = [
    {
      type: "text",
      placeholder: "Start your topic",
      value: title,
      onChange:(e:any)=>setTitle(e.target.value),
    }
  ];

  const [posts, setPosts] = useState([]);
  const [currPost, setCurrPost] = useState();
  const {createPost,getCurrPost,makeComment,connectFriend,repost} = userPostApis();

  const handleCreatePost =async(image:String|null) => {
    try {
      // console.log("Post", title, description);
      console.log("Image",image);
      const create=await createPost({title, description,source:image});
      // console.log("Post", create);
      toast.success("Post created successfully!");
      window.location.reload();
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    }
  }

  const handleGetUserPosts = useCallback(async() => {
    try {
        // console.log("Post", title, description);
        const getPosts = await api.get("/forum/user-post");

        // console.log("Post", getPosts.data.data);
        setPosts(getPosts.data.data.slice().reverse());
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    }
  }, [setPosts]); 

  

useEffect(() => {
    handleGetUserPosts();
}, [handleGetUserPosts]);

  const handleGetCurrPost = useCallback(async(id:string) => {
    try {
      return await getCurrPost(id);
    } catch (e:any) {
      console.log(e);
      toast.error(e.response.data.message);
    }
  }, [currPost]);

  const handleRepost = async(postId:string) => {
    try {
      // console.log("Post", postId);

      const res=await repost({postId});
      console.log("Post", res);
      toast.success("Post reposted successfully!");
    } catch (e:any) {
      console.log(e);
      toast.error(e.response.data.message);
    }
  };

  const handleComment = async(postId:string,comment:string,parentId?:string) => {
    try {
      console.log("Post", comment,postId,parentId);
      const res=await makeComment({comment,postId,parentId:parentId?parentId:null});
      console.log("Post", res);
      toast.success("Comment added successfully!");
      window.location.reload();
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    }
  }
  const handleConnectFriend = async(payload:any) => {
    const {friendId}=payload;
    try {
      const res=await connectFriend(friendId);
      // console.log("Post", res);
      toast.success("Friend request sent successfully!");
      window.location.reload();
    } catch (e:any) {
      console.log(e);
      toast.error(e.message);
    }
  }

  return {
    formInputSource,
    handleCreatePost,
    handleGetCurrPost,
    posts,
    handleComment,
    handleConnectFriend,
    handleRepost,
    image, setImage,
    description, setDescription
  };
};

