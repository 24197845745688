// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-list-question-wrapper {
    height: 700px;
    background-color: #F2F6FA;
  }`, "",{"version":3,"sources":["webpack://./src/pages/quiz/QuizModule.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;EAC3B","sourcesContent":[".question-list-question-wrapper {\n    height: 700px;\n    background-color: #F2F6FA;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
