import React, { useState } from "react";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import { NavLink } from "react-router-dom";
import { IconButton, Avatar, TextField, Button } from "@mui/material";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { useForum } from "../../utils/Forum";
// import SocketIOClient from "../../pages/Websocket";
import MatchUser from "../../pages/MatchUser";

const style = {
  position: "absolute" as "absolute",
  top: "60%",
  width: "100%",
  height: "40%",
  bgcolor: "background.paper",
  borderRadius: "15px 15px 0px 0px",
};
type PopType = {
  openForm: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
};

const MatchPopUp = ({ openForm, setOpenForm }: PopType) => {
  const { formInputSource, handleCreatePost } = useForum();
  return (
    <div>
      <Modal
        open={openForm}
        onClose={() => setOpenForm(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="flex flex-col gap-5  h-full" >
          <MatchUser setOpenForm={setOpenForm} openForm={openForm}/>
        </Box>
      </Modal>
    </div>
  );
};
export default MatchPopUp;
