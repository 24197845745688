import React, { useState } from "react";
import ActionButton from "../components/ActionButton";
import Logo from "../components/Logo";
import FormInput from "../components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import authService from "../services/authService";
import useToast from "../hooks/useToast";
import userService from "../services/userService";
import Banner from '../assets/gifs/Banner.gif';

const type = process.env.REACT_APP_TYPE;
const IP = process.env.REACT_APP_IP;
const REACT_APP_PROD_SSL_SWITCH = process.env.REACT_APP_PROD_SSL_SWITCH;
const REACT_APP_PROD_SSL_PORT = process.env.REACT_APP_PROD_SSL_PORT;
console.log(REACT_APP_PROD_SSL_SWITCH);
const BACKEND_URL =
  type == "dev" ? "http://localhost:5000/v1" : `${REACT_APP_PROD_SSL_SWITCH}://${IP}:${REACT_APP_PROD_SSL_PORT}/v1`;
const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleLoginChange = (key: string, value: string) => {
    setLoginData({
      ...loginData,
      [key]: value,
    });
  };

  const handleLogin = async () => {
    try {
      const res: any = await authService.login({
        email: loginData.email,
        password: loginData.password,
      });

      console.log(res, "res");
      localStorage.setItem("accessToken", res?.message?.accessToken);
      toast.success("Logged In Successfully!");
      const data = await userService.getProfile();
      console.log(data?.message?.active, "data");
      if (data?.message?.active === true) {
        navigate("/");
      } else {
        navigate("/onboarding");
      }
    } catch (error) {
      console.log(error, "error");
      toast.error("Something went wrong!");
    }
  };

  const googleLogin = () => {
    try {
      window.location.href = `${BACKEND_URL}/auth/google`;
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <div className="flex flex-col items-center h-screen w-screen  bg-cover bg-center" style={{ backgroundImage: `url(${Banner})` }}>
      <Logo className="flex-auto" />
      <div className="mt-auto flex flex-grow flex-col w-full px-6 py-8">
        <h4 className="text-white font-inter font-normal text-lg py-8 text-center -mt-16">
          Log In
        </h4>
        <div className="flex flex-col gap-y-5 ">
          <FormInput
            onChange={(e) => handleLoginChange("email", e.target.value)}
            placeholder="Enter email"
            type="email"
            value={loginData.email}
          />
          <div>
            <FormInput
              onChange={(e) => handleLoginChange("password", e.target.value)}
              placeholder="Enter password"
              type="password"
              value={loginData.password}
            />
            <div className="text-end ml-auto">
              <Link to={"/forgot/password"} className="text-secondary text-sm">
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <ActionButton
            buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md mt-2 py-2"
            buttonText="Log In"
            onClick={() => handleLogin()}
          />
          <p className="text-white font-inter font-normal text-sm text-center my-2">
            Haven't got an account?{" "}
            <Link to="/register" className="text-secondary text-sm">
              Sign Up
            </Link>
          </p>
          <ActionButton
            buttonClass="bg-gradient-to-br from-secondary to-lightSecondary rounded-md mt-2 py-2"
            buttonText="Continue with Google"
            onClick={googleLogin}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
