import React, { useEffect, useState } from 'react'
import ModuleHeader from '../../components/ModuleHeader'
import { useParams } from 'react-router-dom'
import { getLeaderboardData } from '../../services/quizService';
import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import useToast from '../../hooks/useToast';
import { PulseLoader } from 'react-spinners';
import userService from '../../services/userService';

export default function LeaderBoard() {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false)
  const [leaderBoardData, setLeaderBoardData] = useState<any>([])
  const [topRankerData, setTopRankerData] = useState<any>([])
  const [userData, setUserData] = useState<any>();
  
  const toast = useToast()
  const getData = () => {
    setLoading(true)
    id && getLeaderboardData(id).then((item: any) => {
      setLoading(false);
      if (item.status == 200) {
        const data = item.data.message;
        setLeaderBoardData(data);
        const firstThreeValues = data.slice(0, 3)
        setTopRankerData(firstThreeValues);
      }
    }).catch((error) => {
      setLoading(false);
      setError(true)
      toast.error('Error fetching results');
      
    })
  }
  useEffect(() => {
    getData()
    getUserData()
  }, [id])

    if(loading){
      return (<div className='m-40'> <PulseLoader   color='#7A54F0' loading={loading}/> </div>)
  }
  
  function calculateTimeDifference(startTime: string, endTime: string): string {
    const start: Date = new Date(startTime);
    const end: Date = new Date(endTime);

    const minutes: number = differenceInMinutes(end, start);
    const seconds: number = differenceInSeconds(end, start) % 60;

    const formattedSeconds: string = String(seconds).padStart(2, '0');
    
    return `${minutes} : ${formattedSeconds} `;
  }

  const getUserData = async()=>{
    setLoading(true)
    try {
         setLoading(false)
        const resp = await userService.getProfile()
        if(resp.status == 200){
            console.log(resp, 'user data')
            setUserData(resp.message)
        }
    } catch (error) {
         setLoading(false)
        console.log(error)
    }
}
  return (
    <>
    {!error && (
    <div className='min-h-screen'>
      <div className="p-4 h-40 bg-white">
        <ModuleHeader userData={userData} title="Leaderboard" onBack={() => { console.log('back') }} />
      </div>
      <div className="relative flex justify-center items-center  "> {/* Added margin-bottom */}
        <div className=" flex justify-center items-center bg-silverGradient rounded-full w-[75px] h-[75px] absolute left-[40px] ">
          <img className='w-[58px] h-[58px]  rounded-full' src={topRankerData[1]?.user?.profilePic} alt="" />
        </div>
        <div className="w-[88px] h-[88px] absolute flex justify-center items-center bg-goldenGradient rounded-full bottom-[10px]">
          <img className='w-[68px] h-[68px]  rounded-full' src={topRankerData[0]?.user?.profilePic} alt="" />
        </div>
        <div className="flex justify-center items-center w-[75px] h-[75px] absolute right-[40px] bg-bronzGradient rounded-full ">
          <img className='w-[58px] h-[58px]  rounded-full' src={topRankerData[2]?.user.profilePic} alt="" />
        </div>
      </div>
      {/* <div className="text-right mr-4 mt-20" />  */}
      
      <div className="overflow-y-scroll mt-20 pt-3">
  <table className="max-w-full mx-2 bg-white border border-gray-200">
    <thead>
      <tr>
        <th className="px-3 font-medium text-lightPurple py-3 border-b bg-gray-200 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
          Rank
        </th>
        <th className="px-3 font-medium text-lightPurple py-3 border-b bg-gray-200 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
          Name
        </th>
        <th className="px-3 font-medium text-lightPurple py-3 border-b bg-gray-200 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
          Time Taken
        </th>
        <th className="px-3 font-medium text-lightPurple py-3 border-b bg-gray-200 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
          Correct Answers
        </th>
        <th className="px-3 font-medium text-lightPurple  py-3 border-b bg-gray-200 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
          Total Coins
        </th>
      </tr>
    </thead>
    <tbody>
      {leaderBoardData && leaderBoardData.map((item:any) => (

                <tr className="bg-white">
                  <td className="px-3 py-4 border-b text-sm text-gray-900">
                    <div className=" flex justify-center items-center bg-goldenGradient rounded-full w-[35px] h-[35px] ">
                      <img className='w-[25px] h-[25px]  rounded-full' src={item.user.profilePic} alt="" />
                    </div>
                  </td>
                  <td className="px-3 py-4 border-b font-inter text-[12px] font-500 leading-[14.52px] text-center text-textBlack">{item.user.name}</td>
                  <td className="px-3 py-4 border-b font-inter text-[12px] font-500 leading-[14.52px] text-center text-textBlack">{calculateTimeDifference(item.startTime, item.endTime)}</td>
                  <td className="px-3 py-4 border-b font-inter text-[12px] font-500 leading-[14.52px] text-center text-textBlack">{item.correctAnswers}</td>
                  <td className="px-3 py-4 border-b font-inter text-[12px] font-500 leading-[14.52px] text-center text-textBlack">{item.score}</td>
                </tr>

              ))}

            </tbody>
          </table>
        </div>

      </div>
    )}
    </>

  )
}
