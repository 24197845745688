import {api} from "../config/apiConfig";

export const userPostApis = () => {
  const createPost = async (data: any) => {
    console.log("Post", data);
    const res = await api.post("/forum/post", data);
    return res.data;
  };
  const getCurrPost = async (id: string) => {
    const res = await api.get(`/forum/post?id=${id}`);
    return res.data.data;
  };
  const makeComment = async (data: any) => {
    const res = await api.post(`/forum/comment?postId=${data.postId}`, {
      ...data,
    });
    return res.data;
  };
  const repost = async (data: any) => {
    console.log("Post", data.postId);
    const res = await api.patch(`/forum/post/${data.postId}/repost`);
    return res.data;
  };
  
  const connectFriend = async (friendId: string) => {
    const res = await api.post(`/forum/make-friend`, { friendId });
    return res.data;
  };
  return { createPost, getCurrPost, makeComment, connectFriend,repost };
};
