import React, { useState, useEffect } from "react";
import Heading from "../../components/Heading";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { Avatar } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { useChat } from "../../utils/Chat";
import { useAuth } from "../../context/AuthContext";
import { api } from "../../config/apiConfig";

const Chats = () => {
  const { chats,deleteChat } = useChat();
  const { account } = useAuth();
  // console.log(account);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [unreadCounts, setUnreadCounts] = useState<any>({});

  // console.log("Chats", chats);
  useEffect(() => {
    const fetchUnreadMessages = async () => {
      const counts: any = {};
      for (const chat of chats) {
        if (!chat.id) {
          continue;
        }
        const length = await getUnreadMessages(chat.id);
        counts[chat.id] = length;
      }
      setUnreadCounts(counts);
    };
    if (chats.length > 0) {
      fetchUnreadMessages();
    }
  }, [chats]);

  const getUnreadMessages = async (chatId: any) => {
    try {
      const response = await api.get(`chat/get-unreadmessage/${chatId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching unread messages:", error);
      return 0;
    }
  };

  return (
    <div className="pt-[0.5rem] text-md flex flex-col min-h-screen">
      <Heading title="Chat" />
      <div className="flx-row mt-16 space-x-10 px-[1rem] text-darkgrey">
        <Link to="/room" className="font-bold">
          Room
        </Link>
        <Link
          to="/chat"
          className={`${
            pathname === "/chat"
              ? "text-purple font-bold border-b-4   border-purple"
              : ""
          } `}
        >
          Chats
        </Link>
      </div>
      <div className=" py-[2rem] px-[1rem] bg-[#F2F6FA] flex-1 flex flex-col gap-5 h-full">
        <div className="w-full h-12 bg-white  border border-[#E1E8F1] rounded-full outline-none flx-row justify-between px-3 ">
          <SearchIcon className=" text-greyText text-lg" />
          <input
            type="text"
            placeholder="Search Chat ( Coming Soon )"
            className=" px-3  flex-1 font-normal bg-white"
            disabled
          />
          <TuneOutlinedIcon className=" text-greyText text-sm" />
        </div>
        <div className="flex flex-col gap-5">
          {chats &&
            chats.length > 0 &&
            chats?.map((item: any) => {
              const fiend = item.users.filter((i: any) => i.id !== account?.id);
              const { name, id } = fiend[0];
              const message = item.messages[item.messages.length - 1];

              return (
                <div
                  className="flx-row  rounded-lg group  bg-purple shadow-md h-full "
                  
                >
                  <div className="px-1 py-3 hidden group-hover:block">
                    <DeleteOutlineOutlinedIcon className="text-white" onClick={(e)=>{
                      e.preventDefault();
                      deleteChat(item.id);
                    }}/>
                  </div>
                  <div className="bg-white flex-1 flx-row gap-5 rounded-lg h-full p-3 group-hover:translate-x-2"
                  >
                    <div className="flx-row flex-wrap gap-2 w-10 ">
                      <Avatar
                        alt="Remy Sharp"
                        src={account?.profilePic}
                      />
                    </div>
                    <div className="flex-1 flex flex-col gap-1 cursor-pointer" onClick={() => {
                    navigate(`/chat/${id}`);
                  }}>
                      <div className="text-darkgrey text-sm flx-row justify-between">
                        <p className="font-bold text-md">{name}</p>
                        <p className="text-xs">Yesterday</p>
                      </div>
                      <div className="text-darkgrey text-sm flx-row justify-between">
                        <p>
                          {message.type == "text" ? message.content : "Image"}
                        </p>{" "}
                        <button className="rounded-full w-5 h-5 bg-[#01BCB0] text-xs text-white">
                          {unreadCounts[item.id] > 0 && (
                            <button className="rounded-full w-5 h-5 bg-[#01BCB0] text-xs text-white">
                              {unreadCounts[item.id]}
                            </button>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Chats;
