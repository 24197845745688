import React from 'react';
import ReactDOM from 'react-dom';

interface CustomModalProps {
    modalTitle: string;
    modalBody: React.ReactNode;
    onClose?: () => void; // Optional close function
    onYes?: () => void;   // Optional callback for Yes/Okay button
    onCancel?: () => void; // Optional callback for Cancel button
    outsideClose?: boolean;
    show: boolean;
    required?: boolean;   // Optional flag to enforce modal closing by buttons only
    singleButton?: boolean; // Flag to show either a single "Okay" button or Yes/Cancel buttons
}

const CustomModal: React.FC<CustomModalProps> = ({
    modalTitle,
    modalBody,
    onClose,
    onYes,
    onCancel,
    outsideClose = true,
    show,
    required = false,
    singleButton = false, // Default to showing Yes/Cancel buttons
}) => {
    if (!show) return null;

    // Close modal when clicking outside of it
    const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (outsideClose && !required && e.target === e.currentTarget && onClose) {
            onClose();
        }
    };

    // Handle Yes/Okay button click
    const handleYesClick = () => {
        if (onYes) onYes();
        if (onClose) onClose();
    };

    // Handle Cancel button click
    const handleCancelClick = () => {
        if (onCancel) onCancel();
        if (onClose) onClose();
    };

    const modalContent = (
        <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50  "
            onClick={handleOutsideClick}
        >
            <div className="bg-white h-[251px] w-[350px] p-[23px_20px] " style={{ borderRadius: '12px'}}>
                <div className="flex justify-between items-center border-b pb-2 mb-4">
                    <h2 className="text-xl font-semibold">{modalTitle}</h2>
                    {!required && onClose && (
                        <button onClick={onClose} aria-label="Close modal" className="text-gray-600 hover:text-gray-900">
                            &times;
                        </button>
                    )}
                </div>
                <div className="modal-body">{modalBody}</div>
                <div className="mt-4 flex justify-end space-x-2">
                    {singleButton ? (
                        <button
                            onClick={handleYesClick}
                            className="bg-purpleGradient w-[309px] p-[12px_131px] "
                            style={{ borderRadius: '6px'}}
                            aria-label="Okay"
                        >
                            Okay
                        </button>
                    ) : (
                        <>
                            <button
                                onClick={handleCancelClick}
                                className="bg-greenGradient text-white  w-[309px] p-[12px_131px]"
                                style={{ borderRadius: '6px'}}
                                aria-label="Cancel"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleYesClick}
                                className="bg-purpleGradient rounded-sm w-[309px] p-[12px_131px]"
                                style={{ borderRadius: '6px'}}
                                aria-label="Yes"
                            >
                                Yes
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(modalContent, document.body);
};

export default CustomModal;
