import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDropleft } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import ActionButton from "../components/ActionButton";
import { useNavigate } from "react-router-dom";
import userService from "../services/userService";
import useToast from "../hooks/useToast";
import { getStateByCodeAndCountry } from "country-state-city/lib/state";
const type = process.env.REACT_APP_TYPE;
const IP = process.env.REACT_APP_IP;
const REACT_APP_PROD_SSL_SWITCH = process.env.REACT_APP_PROD_SSL_SWITCH;
const REACT_APP_PROD_SSL_PORT = process.env.REACT_APP_PROD_SSL_PORT;
const BACKEND_URL =
  type == "dev" ? "http://localhost:5000" : `${REACT_APP_PROD_SSL_SWITCH}://${IP}:${REACT_APP_PROD_SSL_PORT}`;
const Profile = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState<any>();
  const [accountSettings, setAccountSettings] = useState<any>();
  const [discoverySettings, setDiscoverySettings] = useState<any>();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: any) => {
    if (e.target.files) {
      handleSubmit(e.target.files[0]);
    }
  };

  async function getS3SignUrl(filename: string, filetype: string) {
    const headers = new Headers({ "Content-Type": "application/json" });
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ fileName: filename, fileType: filetype }),
    };
    const response = await fetch(`${BACKEND_URL}/v1/user/uploadImage`, options);
    const presignedUrl = await response.json();
    return presignedUrl;
  }

  async function pushProfilePhotoToS3(presignedUrl: string, file: File) {
    const myHeaders = new Headers({ "Content-Type": file.type });
    const response = await fetch(presignedUrl, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    });
    return response;
  }

  function getUrlUpToExtension(url: string) {
    const queryParamIndex = url.indexOf("?");
    const endIndex = queryParamIndex !== -1 ? queryParamIndex : url.length;
    return url.substring(0, endIndex);
  }

  async function handleSubmit(file: any) {
    if (file) {
      const data = await getS3SignUrl(file.name, file.type);
      if (data.url) {
        const response = await pushProfilePhotoToS3(data.url, file);
        if (response) {
          updateProfilePic(getUrlUpToExtension(response.url));
        } else {
          toast.error("Upload failed");
        }
      }
    }
  }

  const updateProfilePic = async (url: string) => {
    try {
      const res = await userService.updateUser({
        profilePic: url,
      });
      toast.success("Profile photo updated successfully");
      getProfile();
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/auth");
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const res = await userService.getProfile();
      setProfileData(res.message);
      setAccountSettings({
        Name: res?.message?.name ? res?.message?.name : "No Data",
        "Mob. No.": res?.message?.phoneNumber
          ? res?.message?.phoneNumber
          : "No Data",
        Birthday: res?.message?.dob
          ? new Date(res?.message?.dob).toLocaleDateString()
          : "No Data",
        Gender: res?.message?.gender ? res?.message?.gender : "No Data",
      });
      setDiscoverySettings({
        College: res?.message?.collegeName
          ? res?.message?.collegeName
          : "No Data",
        City: res?.message?.city ? res?.message?.city : "No Data",
        State: res?.message?.state
          ? getStateByCodeAndCountry(res?.message?.state, "IN")?.name
          : "No Data",
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <div className="flex flex-col h-screen w-screen">
      <div className="flex flex-col  bg-gradient-to-br h-[1/2]  rounded-b-3xl from-purple to-lightPurple px-4 py-8 gap-y-8">
        <div className="flex flex-row items-center justify-between ">
          <div className="flex flex-row items-center gap-x-3">
            <IoIosArrowDropleft
              size={24}
              color="white"
              onClick={() => navigate("/")}
            />
            <p className="font-roboto text-white font-normal text-2xl ">
              Profile
            </p>
          </div>
          <button
            className="bg-lightSecondary px-2 py-2 rounded-xl"
            onClick={() => navigate("/onboarding", { state: profileData })}
          >
            <p className="font-inter text-white text-xs">Edit Profile</p>
          </button>
        </div>
        <div className="h-36 flex flex-col items-center justify-center ">
          <div className="relative">
            {profileData?.profilePic ? (
              <img
                src={profileData?.profilePic}
                className="rounded-full w-[132px] h-[132px] object-contain border-2 border-gray-700 bg-white p-2"
              />
            ) : (
              <FaUserCircle size={132} color="white" />
            )}
            <div className="bg-white h-6 w-6 rounded-full flex items-center justify-center absolute top-2 -right-1">
              <MdModeEdit
                size={12}
                color="blue"
                onClick={() => inputRef.current?.click()}
              />
              <input
                type="file"
                className="hidden"
                ref={inputRef}
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
          </div>
          {profileData?.username ? (
            <p className="text-white font-inter text-lg mt-2">
              {profileData?.username}
            </p>
          ) : (
            <p className="text-white font-inter text-lg mt-2">
              {profileData?.name}
            </p>
          )}
        </div>
      </div>
      <div className="px-4 py-4 flex flex-col gap-y-4 text-darkgrey">
        <h4 className="font-roboto  font-medium text-sm">Account Settings</h4>
        <div className="flex flex-col gap-y-2">
          {accountSettings &&
            Object.keys(accountSettings).map((key, index) => (
              <div
                key={index}
                className="border-[1px] border-greyText px-2 py-2 rounded-md"
              >
                <div className="flex flex-row items-center justify-between">
                  <p className="font-roboto font-medium text-sm">{key}</p>
                  <p className="font-roboto text-greyText font-medium text-sm">
                    {accountSettings[key]}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="px-4 py-4 flex flex-col gap-y-4 text-darkgrey">
        <h4 className="font-roboto font-medium text-sm">Discovery Settings</h4>
        <div className="flex flex-col gap-y-2">
          {discoverySettings &&
            Object.keys(discoverySettings).map((key, index) => (
              <div
                key={index}
                className="border-[1px] border-greyText px-2 py-2 rounded-md"
              >
                <div className="flex flex-row items-center justify-between">
                  <p className="font-roboto font-medium text-sm">{key}</p>
                  <p className="font-roboto text-greyText font-medium text-sm">
                    {discoverySettings[key]}
                  </p>
                </div>
              </div>
            ))}
        </div>
        <ActionButton
          buttonClass="bg-gradient-to-br from-secondary to-lightSecondary rounded-md py-2"
          buttonText="Log Out"
          onClick={handleLogout}
        />
      </div>
    </div>
  );
};

export default Profile;
