import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useToast from "../hooks/useToast";

const GoogleLogin = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  console.log(urlParams.get("accessToken"), "params");
  const accessToken = urlParams.get("accessToken");
  useEffect(() => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      toast.success("Logged In Successfully!");
      navigate("/profile");
    }
  }, [accessToken]);
  return <></>;
};

export default GoogleLogin;
