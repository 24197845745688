import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const GuestRoutes = () => {
    const isLoggedIn = localStorage.getItem("accessToken");
  return (
    isLoggedIn ? <Navigate to="/" /> : <Outlet />
  )
}

export default GuestRoutes