import React, { useEffect, useMemo } from "react";
import TabBar from "../components/TabBar";
import userService from "../services/userService";
import { FaUserCircle } from "react-icons/fa";
import COLORS from "../constants/Colors";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Avatar, IconButton } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import LinearProgress from "@mui/material/LinearProgress";
import SearchIcon from "@mui/icons-material/Search";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { Link } from "react-router-dom";
import Post from "../components/Post";
import { useForum } from "../utils/Forum";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SideBar from "./SideBar";
import { useAuth } from "../context/AuthContext";

const Home = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = React.useState<any>({});
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const { account } = useAuth();
  useEffect(() => {
    getProfile();
  }, []);
  const { posts } = useForum();

  const getProfile = async () => {
    try {
      const res = await userService.getProfile();
      console.log(res, "profile");
      setProfileData(res.message);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleProfileProgress = useMemo(() => {
    let count = 0;
    if (account?.dob) count++;
    if (account?.email) count++;
    if (account?.name) count++;
    if (account?.active) count++;
    if (account?.city) count++;
    if (account?.collegeId) count++;
    if (account?.collegeName) count++;
    if (account?.dob) count++;
    if (account?.yearofstudy) count++;
    if (account?.stream) count++;
    if (account?.profilePic) count++;
    if (account?.rating) count++;
    if (account?.kyc) count++;

    return (count / 12) * 100;
  }, [account]);

  return (
    <>
      <div className="px-4 py-5 flex flex-col gap-5 mb-12">
        <div className=" flx-row justify-between">
          <div className="flx-row space-x-2">
            <div
              className="relative inline-block "
              onClick={() => navigate("/profile")}
            >
              {/* {!account?.profilePic ? (
                <FaUserCircle size={32} color={COLORS.purple} />
              ) : (
                <img
                  src={account.profilePic}
                  alt="profile"
                  className="rounded-full w-8 h-8 "
                />
              )} */}
              <Avatar
                alt="Profile Pic"
                src={profileData.profilePic}
                sx={{ width: 35, height: 35 }}
              />
              {!profileData?.active && (
                <div className="bg-red-500 rounded-full w-2 h-2 absolute top-0 right-0"></div>
              )}
            </div>
            {"  "}
            <IconButton className="">
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.9573 16.485H0.468018V14.7361H1.34248V8.64192C1.34248 4.28009 4.86658 0.744629 9.21267 0.744629C13.5588 0.744629 17.0829 4.28009 17.0829 8.64192V14.7361H17.9573V16.485ZM3.09141 14.7361H15.3339V8.64192C15.3339 5.24638 12.5934 2.49356 9.21267 2.49356C5.83199 2.49356 3.09141 5.24638 3.09141 8.64192V14.7361ZM7.02651 17.3595H11.3988C11.3988 17.9393 11.1685 18.4953 10.7585 18.9053C10.3485 19.3153 9.79247 19.5456 9.21267 19.5456C8.63286 19.5456 8.0768 19.3153 7.66682 18.9053C7.25683 18.4953 7.02651 17.9393 7.02651 17.3595Z"
                  fill="black"
                />
              </svg>
            </IconButton>

            <IconButton className="-translate-x-1">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_422_4503)">
                  <path
                    d="M15.1209 14.2835L1.78378 9.6321C1.56616 9.55618 1.37659 9.41623 1.23994 9.23063C1.10329 9.04503 1.02595 8.82245 1.01807 8.5921V7.34639C1.01901 7.11014 1.09314 6.87999 1.23026 6.68761C1.36739 6.49522 1.56076 6.35006 1.78378 6.2721L15.1209 1.6321C15.2065 1.60318 15.2978 1.59498 15.3872 1.60817C15.4766 1.62137 15.5616 1.65559 15.6353 1.70801C15.7089 1.76044 15.769 1.82958 15.8107 1.90976C15.8524 1.98994 15.8745 2.07887 15.8752 2.16925V13.7464C15.8745 13.8368 15.8524 13.9257 15.8107 14.0059C15.769 14.0861 15.7089 14.1552 15.6353 14.2076C15.5616 14.26 15.4766 14.2943 15.3872 14.3075C15.2978 14.3207 15.2065 14.3125 15.1209 14.2835Z"
                    stroke="#000001"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.1381 12.5692C10.0419 13.3631 9.64667 14.0904 9.03296 14.6031C8.41924 15.1157 7.63318 15.3752 6.83486 15.3286C6.03655 15.282 5.28603 14.9328 4.73615 14.3522C4.18628 13.7716 3.87841 13.0032 3.87524 12.2035V10.3864"
                    stroke="#000001"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_422_4503">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0.446533 0.489258)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </IconButton>
          </div>
          <div className="">
            <IconButton
              onClick={() => {
                setOpenSidebar(!openSidebar);
              }}
            >
              <MenuRoundedIcon />
            </IconButton>
          </div>
        </div>
        <div>
          <h4 className="text-xl font-normal font-roboto">
            Hi {profileData?.name},
          </h4>
          <p className="text-lightPurple font-normal text-xs">
            You have read 3 unseen matches{" "}
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-base font-medium">Complete your profile</p>
          <LinearProgress
            sx={{
              borderRadius: 2,
              background: "#99A9D1",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#42EBAB",
                borderRadius: 2,
              },
            }}
            value={handleProfileProgress}
            variant="determinate"
          />
        </div>
        <div className="w-full h-12 bg-[#F2F6FA] border border-[#E1E8F1] rounded-full outline-none flx-row justify-between px-3 ">
          <SearchIcon className=" text-greyText text-lg" />
          <input
            type="text"
            placeholder="Search Topic ( Coming Soon )"
            className=" px-3  flex-1 font-normal bg-[#F2F6FA]"
            disabled
          />
          <TuneOutlinedIcon className=" text-greyText text-sm" />
        </div>

        <div className="flex flex-col gap-3 ">
          <div className="flx-row justify-between">
            <p className="text-base font-medium">New Matches</p>
            <Link className="text-purple text-xs " to="/">
              See more
            </Link>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-row gap-3 p-1 rounded-md bg-[#F2F6FA]">
              <div className="flex-1 flx-row gap-2">
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 52, height: 52 }}
                />
                <div className="">
                  <p className="font-medium text-base">Duo Cortex</p>
                  <p className="text-greyText text-xs">Software Developer</p>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <button className="bg-gradient-to-t from-[#7A54F0] to-[#BE5AFF] text-white px-3 py-1 rounded-lg text-xs">
                  Accept
                </button>
                <button className="bg-gradient-to-t from-[#42EBAB] to-[#01BCB0] text-white px-3 py-1 rounded-lg text-xs">
                  Ignore
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flx-row justify-between">
            <p className="text-base font-medium ">Medico Forum</p>
            <Link className="text-purple text-xs " to="/forum">
              See more
            </Link>
          </div>
          <div className="flex-col gap-5 flex">
            {posts &&
              posts.length > 0 &&
              posts.slice(0, 1)?.map((item: any) => (
                <div key={item?.id}>
                  <Post item={item} />
                </div>
              ))}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flx-row justify-between">
            <p className="text-base font-medium">Medico Library</p>
            <Link className="text-purple text-xs " to="/coming-soon">
              See more
            </Link>
          </div>
          <div className="">
            <div className="flx-row p-1 rounded-sm shadow-md gap-2">
              <button className="bg-extralightgrey p-[0.5px] rounded-full text-darkgreen text-xs">
                <AddOutlinedIcon />
              </button>
              <div className="flex flex-col flex-1 gap-1">
                <p className="text-sm font-normal">First Year</p>
                <hr className="border-lightgrey " />
                <p className="text-xs font-normal text-darkgreen">
                  0/124 modules
                </p>
              </div>
            </div>
          </div>
        </div>
        <TabBar />
      </div>
      {openSidebar && (
        <div className="fixed h-screen top-0 left-0 z-50">
          <SideBar />
        </div>
      )}
    </>
  );
};

export default Home;
