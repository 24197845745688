import React, { useEffect, useState } from 'react'

import { MdAccessTime } from "react-icons/md";
import { TbNotes } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ModuleHeader from '../../components/ModuleHeader'
import WalletHeader from '../../components/WalletHeader'
import { FiDatabase } from 'react-icons/fi';
import { getAllQuizList } from '../../services/quizService';
import { quize_list } from '../../store/quize/quizeSlice';
import { PulseLoader } from 'react-spinners';
import userService from '../../services/userService';


const QuizList = () => {
    const navigate = useNavigate()
    const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0)
    const [tabData, setTabData] = useState<any>([]);
    const dispatch = useDispatch()
    const quizeList = useSelector(quize_list)
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState<any>(null);

    useEffect(() => {
        const getUserData = async () => {
            setLoading(true)
            try {
                setLoading(false)
                const resp = await userService.getProfile()
                if (resp.status == 200) {
                    setUserData(resp.message)
                }
            } catch (error) {
                setLoading(false)
                console.log(error)
            }
        }

        const fetchQuizListData = async () => {
            setLoading(true)
            await getAllQuizList(selectedTabIndex + 1, dispatch);
            setLoading(false)
        };

        fetchQuizListData()
        !userData && getUserData()
    }, [selectedTabIndex, userData])

    useEffect(() => {
        if (quizeList && quizeList.length > 0) {
            const tags = quizeList.map((item: any) => ({ label: item.tag }))
            setTabData(tags);
        }
    }, [quizeList])

    if (loading) {
        return (<div className='m-40'> <PulseLoader color='#7A54F0' loading={loading} /> </div>)
    }
    return (
        <div className="bg-lightestBlue min-h-screen pb-12">
            <div className="p-4 h-40 bg-white rounded-b-xl shadow-md">
                <div className="mb-4">
                    <ModuleHeader userData={userData} title="Quiz" onBack={() => { console.log('back') }} />
                </div>
                <div className="mb-3">
                    <WalletHeader />
                </div>
                <h4 className="text-xl font-normal font-roboto">Hi {userData?.name},</h4>
                <p className="text-lightPurple font-normal text-xs">Let's test your knowledge</p>
            </div>
            <div className="p-4 h-full">
                <div className="text-sm font-medium text-center text-gray-500 dark:text-gray-400` overflow-x-auto overflow-hidden">
                    <ul className="flex -mb-px">
                        {Array.from({ length: 8 }, (_, index) => (
                            <li key={index} className="me-2" onClick={() => setSelectedTabIndex(index)}>
                                <p
                                    className={`inline-block font-roboto  text-nowrap text-lg font-roboto font-medium p-2 rounded-t-lg ${selectedTabIndex === index ? 'text-lightPurple' : ''}`}
                                >
                                    {'Sem' + ' ' + (index + 1)}
                                    <hr className={`w-10 h-1 mx-auto ${selectedTabIndex === index ? 'block bg-lightPurple' : 'none'}`} />
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="my-4">
                    {quizeList.length > 0 ? quizeList.map((quiz: any, index: any) => (

                        <div key={index} className="p-3 rounded-md shadow-sm mb-3 bg-white flex gap-5 relative" onClick={() => navigate(`/quiz-details/${quiz.id}`)}>
                            <img src={quiz.quizIcon} className="rounded-md h-[90px] w-[80px]" alt="quiz_image" />
                            <div className=' max-w-xs w-[250px]'>
                                <p className="text-lightPurple font-roboto font-medium text-xl truncate">{quiz.name}</p>
                                <div className="flex gap-1 items-center">
                                    <TbNotes className="text-greyText w-3 h-3" />
                                    <p className="text-greyText font-roboto text-sm"> Questions {quiz.numberOfQuestions}</p>
                                </div>
                                <div className="flex gap-1 items-center">
                                    <MdAccessTime className="text-greyText w-3 h-3" />
                                    <p className="text-greyText font-roboto text-sm">Time: {quiz.duration}</p>
                                </div>
                                <div className="flex gap-1 items-center">
                                    <FiDatabase color="#01bcb0" />
                                    <p className="text-greyText font-roboto text-sm">Prize: {quiz.reward}</p>
                                </div>
                            </div>
                            <div className="absolute right-3 top-2 min-w-[70px]">
                                <div className="flex items-center gap-1 mb-1 justify-end mr-1">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-1 mt-8">
                                            <span className='text-lightPurple mr-2 mb-1'>Entry</span>
                                        </div>
                                    </div>
                                </div>
                                <div>

                                    <div className="bg-lightPurple rounded-full flex items-center justify-center p-1">
                                        <span className="text-white font-roboto text-xl">{quiz?.wager}</span>
                                        <FiDatabase color="#ffffff" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : <div className='text-center text-sm font-roboto text-greyText font-bold'>No Quiz found.</div>}
                </div>

            </div>
        </div >
    )
}

export default QuizList