import React, { useEffect, useState } from 'react'
import { GoTrophy } from 'react-icons/go';
import { useNavigate, useParams } from 'react-router-dom';
import { differenceInMinutes, differenceInSeconds } from 'date-fns';

import ModuleHeader from '../../components/ModuleHeader'
import { getSingleDataFromSession } from '../../utils/localStorageService';
import { deleteQuiz, getResults } from '../../services/quizService';
import Timer from '../../components/Timer';

import AccordionItem from '../../components/Accordion';
import useToast from '../../hooks/useToast';
import { PulseLoader } from 'react-spinners';
import userService from '../../services/userService';

const Result = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const toast = useToast()
    const [resultsData, setResultsData] = useState<any>([])
    const naviagtion = useNavigate()
    const { id } = useParams()
    const [userData, setUserData] = useState<any>()
    function calculateTimeDifference(startTime: string, endTime: string): string {
        const start: Date = new Date(startTime);
        const end: Date = new Date(endTime);

        const minutes: number = differenceInMinutes(end, start);
        const seconds: number = differenceInSeconds(end, start) % 60;

        return `${minutes}:${seconds}`;
    }

    const getResultData = () => {
        const sessionId = getSingleDataFromSession('quizSessionId')
        setLoading(true)
        id && getResults(id, sessionId).then((resp: any) => {
            setLoading(false)
            if (resp.status == 200) {
                const data = resp.data.message
                setResultsData(data)
            }
        }).catch((error: any) => {  // Fixed this line
            setLoading(false);
            console.log(error);
            setError(true)
            toast.error('Error fetching results');
            // setLoading(true)

        });
    }
    useEffect(() => {
        getResultData()
        getUserData()

    }, [id])

    if (loading) {
        return (<div className='m-40'> <PulseLoader color='#7A54F0' loading={loading} /> </div>)
    }
    const getUserData = async()=>{
        setLoading(true)
        try {
             setLoading(false)
            const resp = await userService.getProfile()
            if(resp.status == 200){
                console.log(resp, 'user data')
                setUserData(resp.message)
            }
        } catch (error) {
             setLoading(false)
            console.log(error)
        }
    }
  const deleteQuizData = () =>{
    id && deleteQuiz(id)
  }
    return (
        <>
            {!error && (

                <div className="bg-lightestBlue min-h-screen pb-12">
                    <div className="p-4 h-45 bg-white rounded-b-xl shadow-md">
                        <div className="mb-3">
                            <ModuleHeader redirectToHome userData={userData} title="Result" onBack={deleteQuizData} />
                        </div>
                        <div>
                            <div className='flex mb-2 items-center justify-between'>
                                <div className='flex items-center'>
                                    <div className='p-4 mr-2 rounded-full bg-lightPurple'></div>
                                    <p>Your Answer</p>
                                </div>
                                <div className="text-right flex items-center"> {/* Added margin-top */}
                                    <p>Previous Leaderboard</p>
                                    <button className="ml-2 p-3 bg-gray-200 text-lightPurple rounded" onClick={() => naviagtion(`/leaderboard/${id}`)}>
                                        <GoTrophy />
                                    </button>
                                </div>
                            </div>
                            <div className='flex items-center bg-greenGradient_light rounded-lg'>
                                <div className='p-4 mr-2 bg-greenGradient  rounded-full'></div>
                                <p>Corrent Answer</p>
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-between pt-2  '>
                                <div className='flex'>
                                    <span className='text-lightPurple font-roboto text-[16px] font-500  text-left mr-2'>Time Taken :</span>{calculateTimeDifference(resultsData?.score?.startTime, resultsData?.score?.endTime)}
                                </div>
                                <span className='text-lightPurple font-roboto text-[16px] font-500 leading-[18.75px] text-left flex items-center'>Score :  <p className='text-greenGradient'>{resultsData?.score?.score}</p></span>
                            </div>
                        </div>
                    </div>
                    <div className="form-wrapper pt-3">
                        <div className="form-stepper-wrapper flex overflow-x-scroll w-full">
                            <div className='overflow-y-scroll ' >
                                {resultsData?.userAnswers && resultsData?.userAnswers.map((item: any, index: any) => (
                                    <div>
                                        {/* Sample Question */}
                                        <div className="p-4 rounded bg-white rounded mx-2 mb-2">
                                            <div className="font-roboto text-[16px] font-500 leading-[18.75px] text-left mb-2">{item?.question}</div>
                                            {item.options.map((option: string, optionIndex: number) => (
                                                <>
                                                    <div className="question-options flex items-center mb-2 mt-2 max-w-full cursor-pointer" key={optionIndex} >
                                                        <span
                                                            className={`
                                                                 ${option === item.correctAnswer
                                                                    ? "bg-greenGradient"
                                                                    : option === item.userAnswer
                                                                        ? "bg-purpleGradient"
                                                                        : "bg-greyText"} 
                                                                             text-white rounded-full flex items-center justify-center w-7 mr-2 h-7
                                                                             `}
                                                        >
                                                            {String.fromCharCode(65 + optionIndex)}
                                                        </span>
                                                        <span className='max-w-full truncate font-roboto text-12px font-400 leading-14.06px text-left text-textBlack'>{option}</span>
                                                    </div>
                                                    <div>
                                                    </div>
                                                </>
                                            ))}

                                            <div className='font-roboto w-100  truncate font-normal leading-[11.72px] text-left'>
                                                {/* Note: {item.explanation} */}
                                                <AccordionItem key={index} label=" See Description" data={`Note:${item.explanation}`} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </>
    )
}

export default Result