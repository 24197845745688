// @ts-nocheck

import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../components/Logo";
import ActionButton from "../components/ActionButton";
import { Input } from "@mui/material";
import logo from "../assets/icons/verify-logo.svg";
import authService from "../services/authService";
import { LoaderCircle } from "lucide-react";
import { IoIosArrowDropleft } from "react-icons/io";
import COLORS from "../constants/Colors";
function VerifyCode() {
  const type = process.env.REACT_APP_TYPE;
  const IP = process.env.REACT_APP_IP;
  const REACT_APP_PROD_SSL_SWITCH = process.env.REACT_APP_PROD_SSL_SWITCH;
const REACT_APP_PROD_SSL_PORT = process.env.REACT_APP_PROD_SSL_PORT;
  const BACKEND_URL =
    type == "dev" ? "http://localhost:5000" : `${REACT_APP_PROD_SSL_SWITCH}://${IP}:${REACT_APP_PROD_SSL_PORT}`;
  //   const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [resetCode, setResetCode] = useState("");
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [sent, setsent] = useState(false);
  const { mail } = useParams();
  //   useEffect(() => {
  //     setEmail(decodeURIComponent(mail));
  //   }, []);
  const sendCode = async () => {
    setIsResendLoading(true);
    try {
      const res = await axios.post(`${BACKEND_URL}/v1/user/forgot/password`, {
        email: mail,
      });

      toast.success(res.data.message);
      setIsResendLoading(false);
      setsent(true);
    } catch (err) {
      toast.error(err.response.data.message);
      setIsResendLoading(false);
    }
  };

  const verifyCode = async () => {
    setIsVerifyLoading(true);
    try {
      const res = await axios.post(`${BACKEND_URL}/v1/user/verify/code`, {
        email: mail,
        resetCode,
      });

      toast.success(res.data.message);
      setIsVerifyLoading(false);
      setsent(true);
      navigate(`/reset/password/${encodeURIComponent(mail)}`);
    } catch (err) {
      //   console.log("Error", err.response.data.message);
      toast.error(err.response.data.message);
      setIsVerifyLoading(false);
    }
  };

  return (
    <div>
      <div className="flex flex-col min-h-screen my-8 mx-2 mr-2">
        <div className="flex flex-row items-center justify-between mr-6">
          <IoIosArrowDropleft
            size={34}
            color={COLORS.purple}
            onClick={() => navigate("/forgot/password")}
          />

          <p
            className={`font-roboto font-normal text-sm text-purple ${
              mail.trim() ? " cursor-pointer" : "cursor-not-allowed"
            }`}
            onClick={() => {
              if (mail.trim()) {
                navigate(`/reset/password/${encodeURIComponent(mail)}`);
              }
            }}
          >
            Skip
          </p>
        </div>
        <div className="my-2">
          <img src={logo} className="w-[300px] mt-6 mx-6 justify-center" />
          <div className="bg-white p-2 mt-14 rounded-2xl">
            <div>
              <div className="text-2xl font-roboto font-normal  text-center">
                Change Password{" "}
              </div>
              <div className="mt-auto flex flex-col gap-y-3 w-full px-6 py-10">
                <div>
                  {" "}
                  <input
                    placeholder="Enter Code"
                    className="bg-[#F2F6FA] border text-xs border-gray-400 rounded-xl mt-2 p-4 w-full"
                    onChange={(e) => setResetCode(e.target.value)}
                  />{" "}
                </div>
                {!isResendLoading ? (
                  <div
                    onClick={sendCode}
                    className=" text-purple cursor-pointer font-normal text-xs -mt-2 -mb-2 text-right"
                  >
                    {" "}
                    Resend{" "}
                  </div>
                ) : (
                  <div className="  flex text-purple justify-end cursor-pointer font-normal text-xs -mt-2 -mb-2 text-right">
                    {" "}
                    <LoaderCircle className="animate-spin text-purple text-xs" />
                    <div className="text-purple">Resending Code </div>
                  </div>
                )}

                {!isVerifyLoading ? (
                  <ActionButton
                    buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md mt-8 "
                    buttonText="Verify Code"
                    onClick={verifyCode}
                  />
                ) : (
                  <div className="bg-gradient-to-br p-4 flex justify-between from-purple to-lightPurple rounded-md">
                    {" "}
                    <LoaderCircle className="animate-spin text-white" />
                    <div className="text-white">Verifying Code </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyCode;
