export function storeSingleData({ data, key }: { data: any; key: string }) {
    localStorage.setItem(key, data);
}
export function storeMultiData({ data, key }: { data: any; key: string }) {
    const formatedData = JSON.stringify(data);
    localStorage.setItem(key, formatedData);
}

export function getMultiData(key: string) {
    const getData = localStorage.getItem(key)
    if (getData) {
        return JSON.parse(getData)
    } else {
        return undefined;
    }
}
export function getSingleItem(key: string) {
    const data = localStorage.getItem(key);
    return data;
}
export function storeSingleDataInSession({ data, key }: { data: any; key: string }) {
    sessionStorage.setItem(key, JSON.stringify(data));
}

export function getSingleDataFromSession(key: string): any | null {
    const storedData = sessionStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : null;
}

export function storeQuestionAnsLocal({ questionId, ans }: { questionId: string, ans: any }) {
    const storedData = JSON.parse(localStorage.getItem('questionList') || '');
    
    if (storedData) {
        const updatedData = storedData.map((item: any) => {
            if (item.id == questionId) {
                console.log(`Updating question with id: ${questionId} with answer: ${ans}`);
                return { ...item, answer: ans }; // Update the answer for the matched question
            }
            return item;
        });

        console.log(updatedData, 'Updated Data');
        storeMultiData({ data: updatedData, key: 'questionList' });
    }
}
