import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Heading from "../../components/Heading";
import MatchPopUp from "../../components/popup/MatchPopup";
import { api } from "../../config/apiConfig";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

type User={
  name:string;
  email:string;
  profilePic?:string;
  bio?:string;
}

const Match = () => {
  const { pathname } = useLocation();
  const [matchedUser,setMatchedUser]=useState<User>(); 
  const [openForm, setOpenForm] = React.useState(false);
  const { account } = useAuth();

  const navigate=useNavigate();
  useEffect(() => {
    const userId=pathname.split("/")[2];
    console.log(userId);

    const fetchMatchedUser=async()=>{
      try{
        const res=await api.get(`/user/user-info?userId=${userId}`);
        console.log("The matched user details",res.data);
        setMatchedUser(res.data.message);
      }
      catch(error){
        console.log(error);
      }
    }

    fetchMatchedUser();
  }, []);


  return (
    <div className="py-[0.5rem]  text-md flex flex-col gap-5">
      <Heading title="Search" />
      <div className="w-full mt-16 px-[1rem] flex flex-col gap-5">
        <div className="w-full h-12 bg-[#F2F6FA] border border-[#E1E8F1] rounded-full outline-none flx-row justify-between px-3 ">
          <SearchIcon className=" text-greyText text-lg" />
          <input
            type="text"
            placeholder="Search Topic ( Coming Soon )"
            className=" px-3  flex-1 font-normal bg-[#F2F6FA]"
            disabled
          />
          <TuneOutlinedIcon className=" text-greyText text-sm" />
        </div>
        <div className="flx-row gap-3 justify-between px-[1rem] text-darkgrey font-semibold ">
          <Link
            to="/matching"
            className={`${
              pathname.includes("/matching")
                ? "text-purple font-bold border-b-4   border-purple"
                : ""
            } `}
          >
            Match
          </Link>
          <Link
            to="/chat"
            className="font-bold cursor-default text-gray-500"
            onClick={(e) => e.preventDefault()}
          >
            People
          </Link>
          <Link
            to="/chat"
            className="font-bold cursor-default text-gray-500"
            onClick={(e) => e.preventDefault()}
          >
            Rooms
          </Link>
          <Link
            to="/chat"
            className="font-bold cursor-default text-gray-500"
            onClick={(e) => e.preventDefault()}
          >
            Library
          </Link>
        </div>
      </div>

      <div className=" py-[2rem] px-[1rem] bg-[#F2F6FA] flex-1 flex flex-col gap-5 h-screen -translate-y-5 font-roboto">
        <div className="flex items-center flex-col gap-3">
          <p className="text-[#7A54F0] text-2xl ">Congrats</p>
          <p className="font-semibold text-darkgrey">Your Study mate is here</p>
        </div>
        <div className="flex gap-20 mx-auto items-center justify-center">
          <img
            className="rounded-full w-32 h-32 border"
            src={matchedUser?.profilePic}
            alt="Matched User"
          />
          <img className="rounded-full w-32 h-32 border" src={account?.profilePic} alt="Me" />
        </div>
        <div className="flex flex-col items-center gap-3">
          <p className="font-medium">You've matched with</p>
          <span className="text-4xl text-[#7A54F0]">
            {/* {matchedUserData?.name} */}
            {matchedUser?.name}
          </span>
          <p className="text-xs">A third year student</p>
          <div className="flex flex-col gap-5 mt-10">
            <div className="bg-purpleGradient p-3 rounded-lg text-white w-[300px] flex items-center justify-center cursor-pointer" onClick={()=>{
              navigate(`/chat/${pathname.split("/")[2]}`);
            }}>
              Chat
            </div>
            <button
              className="bg-greenGradient p-3 rounded-lg text-white w-[300px] flex items-center justify-center "
              onClick={() => setOpenForm(!openForm)}
            >
              Search Again
            </button>
          </div>
          <p className="text-xs text-center">
            This match isn’t just about studies—it's about building a
            connection. Share your academic goals, support each other through
            the rigors of med school, and have fun along the way!
          </p>
        </div>
      </div>
      {openForm && <MatchPopUp setOpenForm={setOpenForm} openForm={openForm} />}
    </div>
  );
};

export default Match;
