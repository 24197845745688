import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

interface AccordionItemProps {
    data: string | React.ReactNode;
    label?: string;
}

const AccordionItem = ({ data, label }: AccordionItemProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div
                className={`font-roboto w-80 flex font-normal leading-[11.72px] text-left cursor-pointer ${!isOpen ? 'truncate' : ''}`}
                onClick={toggleAccordion}
            >
                {label}{ isOpen ? <FaAngleUp className='ml-2'/> :
                    <FaAngleDown className='ml-2' />}
            </div>
            {isOpen && (
                <div className="mt-2 text-gray-500 whitespace-normal leading-relaxed">
                    {data}
                </div>
            )}
        </div>
    );
};

export default AccordionItem;
