import React, { useEffect, useState } from "react";

import { MdAccessTime } from "react-icons/md";
import { TbNotes } from "react-icons/tb";
import { TiStarOutline } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

import ModuleHeader from "../../components/ModuleHeader";
import WalletHeader from "../../components/WalletHeader";
import ActionButton from "../../components/ActionButton";
import { getQuizDetails, quizStart } from "../../services/quizService";
import { storeSingleDataInSession } from "../../utils/localStorageService";
import { useDispatch, useSelector } from "react-redux";
import { selected_quize } from "../../store/quize/quizeSlice";
import { PulseLoader } from "react-spinners";
import userService from "../../services/userService";


const QuizDetails = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>();
    const quizDetails = useSelector(selected_quize)
    const [loading, setLoading] = useState<boolean>(false);
    const [userData, setUserData] = useState<any>()
    

    const hanldeQuizStart = async () => {
        const response = id && await quizStart(id);
        if (response && response.status == 200 && response.message.sessionId) {
            storeSingleDataInSession({ data: response.message?.sessionId, key: 'quizSessionId' });
            navigate(`/quiz-questions/${id}`)
        }
    }
    useEffect(() => {
        setLoading(true)
        const fetchQuizListData = async () => {
            id && await getQuizDetails(id, dispatch)
            setLoading(false)
        };
        id && fetchQuizListData()
        getUserData()
    }, [id])
    if(loading){
        return (<div className='m-40'> <PulseLoader   color='#7A54F0' loading={loading}/> </div>)
    }
    const getUserData = async()=>{
        setLoading(true)
        try {
             setLoading(false)
            const resp = await userService.getProfile()
            if(resp.status == 200){
                console.log(resp, 'user data')
                setUserData(resp.message)
            }
        } catch (error) {
             setLoading(false)
            console.log(error)
        }
    }
    return (
        <>
            {quizDetails && (

                <div className="bg-lightestBlue min-h-screen pb-12">
                    <div className="p-4 h-40 bg-white rounded-b-xl shadow-md">
                        <div className="mb-4">
                            <ModuleHeader userData={userData} title="Quiz" onBack={() => { console.log('back') }} />
                        </div>
                        <div className="mb-4">
                            <WalletHeader />
                        </div>
                    </div>

                    <div className="p-4">
                        <p className="mt-3 font-roboto text-[16px] font-500 leading-[18.75px] text-left mb-[16px] ">Brief explanation about this quiz </p>
                        <div className="flex gap-3 mb-[28px]">
                            <div className="rounded-full bg-dark p-3">
                               <TbNotes className="w-6 h-6 text-white" />
                            </div>
                            <div>
                                <p className="font-roboto text-[16px] font-500 leading-[18.75px] text-left">{quizDetails?.questionIds?.length} Question</p>
                                <p className="font-roboto text-[12px] font-400 leading-[14.75px] text-left text-grey-400 text-textBlack">10 point for a correct answer</p>
                            </div>
                        </div>
                        <div className="flex gap-3 mb-[28px]">
                            <div className="rounded-full bg-dark p-3">
                                <MdAccessTime className="w-6 h-6 text-white" />
                            </div>
                            <div>
                                <p className="font-roboto text-[16px] font-500 leading-[18.75px] text-left">{quizDetails.duration} Minutes</p>
                                <p className="font-roboto text-[12px] font-400 leading-[14.75px] text-left text-grey-400 text-textBlack">Total duration of the quiz</p>
                            </div>
                        </div>
                        <div className="flex gap-3 mb-[28px]">
                            <div className="rounded-full bg-dark p-3">
                                <TiStarOutline className="w-6 h-6 text-white" />
                            </div>
                            <div>
                                <p className="font-roboto text-[16px] font-500 leading-[18.75px] text-left">Win {quizDetails.reward} coins</p>
                                <p className="font-roboto text-[12px] font-400 leading-[14.75px] text-left text-textBlack">Answer all questions correctly</p>
                            </div>
                        </div>
                        <p className="font-roboto text-dark font-medium text-lg mb-[24px]">Please read the text below carefully so you can understand it</p>
                        <ul className="m-0 p-0 mb-6">
                            <li className="font-roboto text-[12px] font-400 leading-[14.06px] text-left mb-[16px]">{quizDetails.reward} coins awarded for a correct answer and no marks for a incorrect answer</li>
                            <li className="font-roboto text-[12px] font-400 leading-[14.06px] text-left mb-[16px]">Tap on options to select the correct answer</li>
                            <li className="font-roboto text-[12px] font-400 leading-[14.06px] text-left mb-[16px]">Tap on the bookmark icon to save interesting questions</li>
                            <li className="font-roboto text-[12px] font-400 leading-[14.06px] text-left mb-[16px]">Click submit if you are sure you want to complete all the quizzes</li>
                        </ul>
                        <ActionButton
                            buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md"
                            buttonText="Okay"
                            onClick={hanldeQuizStart}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default QuizDetails;