import React from 'react'
import WelletIcon from '../assets/icons/Wallet.svg'
import CointsIcon from '../assets/icons/coin.svg'
import { FaRupeeSign } from "react-icons/fa";

const WalletHeader = () => {
    return (
        <div className='flex items-center justify-between'>
            <div className='flex items-center'>
                {/* <img src={WelletIcon} alt='wellet_icon' className='w-4 h-4' /> */}
                <img src={CointsIcon} alt='wellet_icon' className='w-4 h-4' />
                <span className='text-lightSecondary font-roboto text-xl mx-1'>20</span>
            </div>

            <div className="relative">
                <div className='bg-lightSecondary text-center p-2 rounded-full'>
                    <FaRupeeSign color='white' fontSize={'0.75rem'} />
                </div>
                <span style={{
                    top: '-18px',
                    right: '-8.5px'
                }} className="absolute p-0 rounded-full leading-none text-white font-bold w-6 h-6 text-4xl">+</span>
                <span className="absolute -top-1 -right-2 p-0 rounded-full leading-none text-lightSecondary font-bold w-4 h-4">+</span>
            </div>
        </div>
    )
}

export default WalletHeader;