import React, { useState, useCallback, useMemo, useEffect } from "react";
import toast from "react-hot-toast";
// import { userPostApis} from "../services/forumService";
import {api} from "../config/apiConfig";
import { useLocation } from "react-router-dom";
import Ably from "ably";

const ably = new Ably.Realtime(
  "vM7HIw.CGET9A:EdKyVx9CIqcaYxHTOjR79eQk31iqsY55LInmxVUX9v4"
);

export const useRoom = () => {
  const [rooms, setRooms] = useState<any>([]);

  const [messages, setMessages] = useState<any>([]);
  const [msg, setMsg] = useState<any>("");
  const [currRoom, setCurrRoom] = useState<any>();

  const [roomName, setRoomName] = useState<string>("");
  const [roomInvite, setRoomInvite] = useState<string>("");
  const [ids, setIds] = useState<string[]>([]);
  const [id, setId] = useState<string>("");

  const { pathname } = useLocation();

  const getMessages = useCallback(async () => {
    if (pathname.includes("room") && pathname.split("/").length > 2) {
      const id = pathname.split("/")[2];

      try {
        const getMessages: any = await api.get(`chat/get-messages/${id}`);
        // console.log("Chat messages", getMessages);
        setMessages(
          getMessages.data.messages.map((msg: any) => {
            return {
              message: msg.content,
              sender: msg.sender.email,
              time: msg.timestamp,
              type: msg.type,
              image:msg.sender.profilePic
            };
          })
        );
        setCurrRoom(getMessages.data.chat);
      } catch (e) {
        console.log(e);
        toast.error("Something went wrong!");
      }
    }
  }, []);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  const getRooms = useCallback(async () => {
    try {
      const getRooms: any = await api.get("/chat/get-rooms");
      // console.log("Room list", getRooms.data);
      setRooms(getRooms.data);
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    }
  }, []);

  useEffect(() => {
    getRooms();
  }, [getRooms]);

  //
  const handleFileUpload = async (file: any) => {
    if (file  ) {
      // console.log("Current Room",currRoom)
      try {
        console.log("the send message url", file);
        await api.post("/chat/create-message", {
          fileUrl: file ,       
          chatId: pathname.split("/")[2],
          type:"link"
        });
        setMsg("");
      } catch (e) {
        console.log(e);
        toast.error("Something went wrong!");
      }
    }
  };

  const handleChat = async (e: any) => {
    e.preventDefault();
    try {

      await api.post("/chat/create-message", {
        content: msg,
        chatId: currRoom.id,
      });
      setMsg("");
      // toast.success("Message sent successfully!");
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    }
  };
  const createRoom = async (e: any) => {
    e.preventDefault();
    try {
      if (ids.length === 0) {
        toast.error("Please add atleast one user!");
        return;
      }
      if(roomName===""){
        toast.error("Please add a room name!");
        return;
      }
      const newRoom = {
        usernames: ids,
        isGroup: true,
        name: roomName,
      };
      console.log(newRoom);
      const createRoom: any = await api.post("/chat/create-chat", newRoom);
      // console.log("Room created", createRoom);
      toast.success("Room created successfully!");
      window.location.href = "/room";
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong!");
    }
  };
  const deleteChat=async(chatId:any)=>{
    try {
      await api.delete(`/chat/delete-chat/${chatId}`);
      toast.success("Chat deleted successfully");
      window.location.reload();
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    }
  }
  useEffect(() => {
    if (!currRoom) return;

    const channel = ably.channels.get(`chat-${currRoom.id}`);

    const handleUpdate = (data: any) => {
      console.log("Data received by ably", data.data);
      if (data.data.chatId === currRoom.id) {
        setMessages((prevMessages: any) => [
          ...prevMessages,
          {
            message: data.data.content,
            sender: data.data.sender.email,
            time: data.data.timestamp,
            type: data.data.type,
          },
        ]);
      }
    };

    channel.subscribe("message", handleUpdate);

    return () => {
      channel.unsubscribe("message", handleUpdate);
    };
  }, [currRoom]);
  return {
    messages,
    handleChat,
    msg,
    setMsg,
    // currChat,
    rooms,
    roomName,
    setRoomName,
    roomInvite,
    setRoomInvite,
    ids,
    setIds,
    id,
    setId,
    createRoom,
    currRoom,
    handleFileUpload,
    deleteChat
  };
};
