import React from "react";
import logo from "../assets/logo.svg";

interface LogoProps {
    className?: string
}


const Logo = ({className}: LogoProps) => {
  return (
    <div className={`flex flex-1 justify-center items-center gap-x-3 ${className}`}>
      <img src={logo} alt="logo" />
      <div>
        <h1 className="font-inter text-3xl font-semibold text-white m-0">
          DuoCortex
        </h1>
        <h4 className="text-md text-white m-0">For Medicos</h4>
      </div>
    </div>
  );
};

export default Logo;
