import React, { useState } from "react";
import home from "../assets/icons/tab-bar/home.svg";
import homeActive from "../assets/icons/tab-bar/home-active.svg";
import forum from "../assets/icons/tab-bar/forum.svg";
import forumActive from "../assets/icons/tab-bar/forum-active.svg";
import quiz from "../assets/icons/tab-bar/quiz.svg";
import quizActive from "../assets/icons/tab-bar/quiz-active.svg";
import room from "../assets/icons/tab-bar/room.svg";
import roomActive from "../assets/icons/tab-bar/room-active.svg";
import match from "../assets/icons/tab-bar/match.svg";
import { Link, useLocation } from "react-router-dom";
import PostInputPop from "./popup/PostInputPop";
import MatchPopUp from "./popup/MatchPopup";

const TabBar = () => {
  const location = useLocation();
  const [openForm, setOpenForm] = useState(false);

  const tabOptions = [
    {
      name: "Home",
      icon: home,
      activeIcon: homeActive,
      url: "/",
    },
    {
      name: "Forum",
      icon: forum,
      activeIcon: forumActive,
      url: "/forum",
    },
    {
      name: "Quiz",
      icon: quiz,
      activeIcon: quizActive,
      url: "/quiz-list",
    },
    {
      name: "Rooms",
      icon: room,
      activeIcon: roomActive,
      url: "/room",
    },
  ];

  const handleMatchClick = (e: any) => {
    e.preventDefault(); // Prevents the default navigation behavior
    setOpenForm(true);
  };

  return (
    <div
      className="bg-white w-full fixed bottom-0 left-0 py-2"
      style={{ boxShadow: "0px -2px 6px rgba(50, 50, 50, 0.15)" }}
    >
      <div className="w-full flex items-center justify-around relative">
        {tabOptions.map((tab, index) => (
          <Link
            key={index}
            to={tab.url}
            className="flex flex-col items-center gap-y-1"
          >
            <img
              src={location.pathname === tab.url ? tab.activeIcon : tab.icon}
              alt={tab.name}
            />
            <p
              className={`text-xs font-roboto ${
                tab.url === location.pathname ? "text-purple" : "text-greyText"
              }`}
            >
              {tab.name}
            </p>
          </Link>
        ))}
        <button
          onClick={handleMatchClick}
          className="bg-purple rounded-full flex flex-col items-center p-2 absolute bottom-6 left-[44%] shadow-lg shadow-purple/50"
        >
          <img className="w-4 h-4" src={match} alt="matches" />
          <p className="text-[8px] text-white font-roboto">Matches</p>
        </button>
      </div>

      {openForm && <MatchPopUp openForm={openForm} setOpenForm={setOpenForm} />}
    </div>
  );
};

export default TabBar;
