import React from "react";
import { Navigate, Outlet, Routes } from "react-router-dom";

const AuthRoutes = () => {
  const isLoggedIn = localStorage.getItem("accessToken");
  console.log(isLoggedIn, "accesstoken");
  return isLoggedIn ? <Outlet /> : <Navigate to="/auth" />;
};

export default AuthRoutes;
