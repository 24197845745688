import axios from "axios";
const type = process.env.REACT_APP_TYPE;
const IP = process.env.REACT_APP_IP;
const REACT_APP_PROD_SSL_SWITCH = process.env.REACT_APP_PROD_SSL_SWITCH;
const REACT_APP_PROD_SSL_PORT = process.env.REACT_APP_PROD_SSL_PORT;
console.log("type", type, "IP", IP);
const BACKEND_URL =
  type == "dev" ? "http://localhost:5000/v1" : `${REACT_APP_PROD_SSL_SWITCH}://${IP}:${REACT_APP_PROD_SSL_PORT}/v1`;
// const BACKEND_URL =
//   "http://localhost:5000/v1" ;
const API_URL = BACKEND_URL;

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use((config: any) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
});

const token = localStorage.getItem("accessToken"); // Corrected method

const apiWithAccessToken = axios.create({
  baseURL: API_URL, // Assuming you want the same base URL as API_URL
  headers: {
    Authorization: `Bearer ${token}`, // Added Bearer scheme and corrected token usage
  },
});

// Exporting both instances as named exports
export { api, apiWithAccessToken };






