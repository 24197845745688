import React, { useState, useEffect } from "react";
import { IconButton, Avatar, TextField, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { api } from "../config/apiConfig";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import Ably from "ably";
import { useForum } from "../utils/Forum";
import { useAuth } from "../context/AuthContext";
const ably = new Ably.Realtime(
  "vM7HIw.CGET9A:EdKyVx9CIqcaYxHTOjR79eQk31iqsY55LInmxVUX9v4"
);

const Post = ({ item }: any) => {
  const handleDate = (date: string) => {
    const today = new Date(date);
    const month = today.toLocaleString("default", { month: "long" });
    return month + " " + today.getDate();
  };
  const { handleRepost } = useForum();
  const [likes, setLikes] = useState(item.likes.length);
  const [isLiked, setIsLiked] = useState(false);
  const { account } = useAuth();

  useEffect(() => {
    const channel = ably.channels.get("posts");

    const handleUpdate = (data: any) => {
      const { id, likes } = data.data;
      // console.log("Id and likes",data.data)
      if (id === item.id) {
        setLikes(likes);
      }
    };

    channel.subscribe("like", handleUpdate);

    return () => {
      channel.unsubscribe("like", handleUpdate);
    };
  }, [item.id]);

  const handleLike = async () => {
    try {
      await api.patch(`/forum/post/${item.id}/like/`);
      if (isLiked) {
        setIsLiked(false);
      } else setIsLiked(true);
    } catch (error) {
      console.error("Error liking post", error);
    }
  };

  const handleDislike = async () => {
    try {
      await api.patch(`/forum/post/${item.id}/dislike/`);
      setIsLiked(false);
    } catch (error) {
      console.error("Error disliking post", error);
    }
  };
  // console.log("Curr post",item)
  return (
    <div className="p-3 rounded-md border text-sm flex flex-col gap-3 bg-[#F2F6FA]">
      {item?.type == "repost" && (
        <>
          <p className="text-sm">
            <b className="text-purple"> {item.repostedBy.name}</b> reposted this
          </p>
          <hr />
        </>
      )}
      <div className="flex flex-row justify-between ">
        <div className="flex-1 flx-row gap-3">
          <Avatar
            alt="Profile"
            src={item?.user?.profilePic}
            sx={{ width: 35, height: 35 }}
          />
          <div className="flex flex-col ">
            <p className="font-medium text-base">{item?.user?.name}</p>
            <p className="font-normal text-xs text-greyText">
              Professor of Calcutta Medical College
            </p>
          </div>
        </div>
        <div className="flex flex-col text-right  text-xs">
          {/* <NavLink to={account?(item?.userId!=account.id?`/chat/${item?.userId}`:''):""}>Chat</NavLink> */}
          {account && (
            <NavLink
              className={`${
                item?.userId != account.id
                  ? "text-purple cursor-pointer"
                  : "text-greyText cursor-default"
              } `}
              to={
                account
                  ? item?.userId != account.id
                    ? `/chat/${item?.userId}`
                    : ""
                  : ""
              }
            >
              Chat
            </NavLink>
          )}
          <p className="p-1 font-normal text-xs text-greyText">
            {handleDate(item?.createdAt)}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-normal text-xs text-darkgrey">{item?.description}</p>
        {item.source && <img
          src={item.source}
          alt="covid"
          className="rounded-md border object-contain max-h-52"
        />}
      </div>
      <div className="flx-row justify-between">
        <div className="w-32 rounded-full border flx-row justify-between">
          <div className="p-1 border-r-2 flex-1 flx-row  text-xs text-greyText">
            <button className="" onClick={handleLike}>
              {isLiked ? (
                <ThumbUpIcon className="text-xs p-1" />
              ) : (
                <ThumbUpOutlinedIcon className="text-xs p-1" />
              )}
            </button>
            <p className=" font-normal text-xs text-darkgrey">
              Upvote: {likes}
            </p>
          </div>
          <div className="p-1  text-greyText">
            <button onClick={handleDislike}>
              <ThumbDownAltOutlinedIcon className="text-xs p-1" />
            </button>
          </div>
        </div>
        <button className="text-xs text-greyText ">
          <SmsOutlinedIcon className="text-sm p p-1" />
          {item?.comments?.length}
        </button>
        <button
          className="text-xs flx-row text-greyText "
          onClick={() => {
            handleRepost(item.id);
          }}
        >
          <LoopOutlinedIcon className="text-sm " />
          {item?.reposts?.length}
        </button>
      </div>
    </div>
  );
};

export default Post;
