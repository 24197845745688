import React from "react";
import TabBar from "../components/TabBar";
import { Outlet } from "react-router-dom";

const TabLayout = () => {
  return (
    <div className="mb-20">
      <Outlet />
      <TabBar />
    </div>
  );
};

export default TabLayout;
