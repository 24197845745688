import React from "react";
import Logo from "../components/Logo";
import ActionButton from "../components/ActionButton";
import { useNavigate } from "react-router-dom";
import Banner from '../assets/gifs/Banner.gif';

const Auth = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center h-screen w-screen  bg-cover bg-center" style={{ backgroundImage: `url(${Banner})` }}
>
      <Logo />
      <div className="flex flex-col gap-y-3 w-full px-6 pt-4 pb-16 bg-black/70">
        <ActionButton
          buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md"
          buttonText="Log In"
          onClick={() => {
            navigate("/login");
          }}
        />
        <ActionButton
          buttonClass="bg-gradient-to-br from-secondary to-lightSecondary rounded-md"
          buttonText="Register"
          onClick={() => {
            navigate("/register");
          }}
        />
      </div>
    </div>
  );
};

export default Auth;
