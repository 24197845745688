import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { question_list, quize_list, updateQuestion } from '../store/quize/quizeSlice';

interface StepperQuestionFormProps {
    onQuestionSubmit: (data: any) => void;
    onFormSubmit: () => void;
    updatedQuestionData?: any;
    currIndex: number;
    setCurrIndex: (val: number) => void;
}

const StepperQuestionForm = ({
    onQuestionSubmit,
    onFormSubmit,
    currIndex,
    setCurrIndex,
}: StepperQuestionFormProps) => {
    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(null);
    const dispatch = useDispatch()
    const questionData = useSelector(question_list)
    const hanldeSubmit = (data: { [key: string]: any | any[] }, currentIndex: any) => {
        const updatedData = {
            ...data,
            selectedOption: ((selectedOptionIndex == 0 || selectedOptionIndex) && data.options[selectedOptionIndex]) || '',
            currentIndex: currentIndex + 1,
        }
        dispatch(updateQuestion({ id: data.id, data: { answer: updatedData.selectedOption } }));
        onQuestionSubmit(updatedData);
        setSelectedOptionIndex(null)
        setCurrIndex(currIndex + 1)
    }
    console.log(questionData);

    return (
        <div className="form-wrapper pt-3 font-roboto font-medium">
            <div className="form-stepper-wrapper flex overflow-x-scroll w-full">
                {questionData.map((item: any, index: number) => (
                    <div
                        key={index}
                        className={`${item.answer || index == currIndex ? ' bg-purpleGradient' : 'bg-greyText'
                            } text-white rounded-full text-center  w-[32px] h-[32px] mx-3 p-1`}
                        onClick={() => {
                            setSelectedOptionIndex(() => null);
                            setCurrIndex(index)
                        }}
                    >
                        {index + 1}

                    </div>
                ))}
            </div>
            <div>
                {questionData.map((item: any, index: number) => (
                    index === currIndex && (
                        <div key={index} className="p-4  rounded ">
                            <div className="font-roboto text-[16px] font-500 leading-[18.75px] text-left mb-2">{item.question}</div>
                            {item.options.map((option: string, optionIndex: number) => (
                                <>
                                    <div className="question-options flex items-center mb-2 mt-2 max-w-full cursor-pointer" key={optionIndex} onClick={() => setSelectedOptionIndex(() => optionIndex)} >
                                        <span className={`${optionIndex === selectedOptionIndex || (item?.answer && item?.answer !== item?.correctAnswer && item?.answer === option) ? "bg-purpleGradient " : item?.answer === item?.correctAnswer && item?.answer === option ? 'bg-greenGradient' : "bg-greyText"} text-white rounded-full  flex items-center justify-center w-7 mr-2 
                                     h-7`}>{String.fromCharCode(65 + optionIndex)}</span>
                                        <span className='max-w-full truncate font-roboto text-12px font-400 leading-14.06px text-left text-textBlack'>{option}</span>
                                    </div>
                                    <div>
                                    </div>
                                </>
                            ))}
                            <button onClick={() => hanldeSubmit(item, currIndex)} className='bg-purpleGradient w-full mt-4 text-white font-semibold p-2 rounded'>
                                Submit
                            </button>
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default StepperQuestionForm;
